import React from 'react'
import { DragLayer } from 'react-dnd'
import { Button, Icon } from 'semantic-ui-react';

class DossierEditorDragLayer extends React.Component {
  getItemStyles(props) {
    const { initialOffset, currentOffset } = props
    if (!currentOffset) {
      return null
    }

    let { x, y } = currentOffset

    let correctionX = 15
    let correctionY = 10
    const transform = `translate(${x}px, ${y}px)`
    return {
      // transform,
      // WebkitTransform: transform,
      position: 'absolute',
      top: `${y - correctionY}px`,
      left: `${x - correctionX}px`,
      zIndex: 400,
    }
  }

  renderItem() {
    const { item, itemType } = this.props
    switch (itemType) {
      case 'attachment':
        return (<Icon name='attach'></Icon>)
      default:
        return (<Icon name='sticky note'></Icon>)
    }
  }

  render() {
    const { item, itemType, isDragging } = this.props

    if (!isDragging) {
      return null
    }

    return (
      <div className={'editor-draglayer with-sidebar'} >
        <div style={this.getItemStyles(this.props)}>{this.renderItem()}</div>
      </div>
    )
  }
}

export default DragLayer( (monitor) => { return {
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  // currentOffset: monitor.getSourceClientOffset(),
  // currentOffset: monitor.getDifferenceFromInitialOffset(),
  currentOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}}) (DossierEditorDragLayer)
