import React from 'react'

import Form from "react-jsonschema-form"

import SemanticFieldTemplate from 'components/forms/fields/SemanticFieldTemplate'
import SemanticArrayField from 'components/forms/fields/SemanticArrayField'
import SemanticSliceField from 'components/forms/fields/SemanticSliceField'

import DossierEditorContainer from 'components/forms/editor/DossierEditorContainer'
import SemanticObjectField from 'components/forms/fields/SemanticObjectField'
import SemanticSchemaField from 'components/forms/fields/SemanticSchemaField'

import SemanticStringField from 'components/forms/fields/SemanticStringField'
import SemanticBooleanField from 'components/forms/fields/SemanticBooleanField'

import SemanticNestedFormField from 'components/forms/fields/SemanticNestedFormField'
import SemanticSelectorField from 'components/forms/fields/SemanticSelectorField'
import SemanticButtonSelectWidget from 'components/forms/widgets/SemanticButtonSelectWidget'

import SemanticTextWidget from 'components/forms/widgets/SemanticTextWidget'
import SemanticPriceWidget from 'components/forms/widgets/SemanticPriceWidget'
import SemanticNumberWidget from 'components/forms/widgets/SemanticNumberWidget'
import SemanticCheckboxWidget from 'components/forms/widgets/SemanticCheckboxWidget'
import SemanticCheckboxDateWidget from 'components/forms/widgets/SemanticCheckboxDateWidget'
import SemanticColorWidget from 'components/forms/widgets/SemanticColorWidget'
import SemanticSelectWidget from 'components/forms/widgets/SemanticSelectWidget'
import SemanticDateWidget from 'components/forms/widgets/SemanticDateWidget'
import SemanticDraftWidget from 'components/forms/widgets/SemanticDraftWidget'
import SemanticDateTimeWidget from 'components/forms/widgets/SemanticDateTimeWidget'
import SemanticTimeDurationWidget from 'components/forms/widgets/SemanticTimeDurationWidget'
import SemanticFileWidget from 'components/forms/widgets/SemanticFileWidget'
import SemanticUpDownWidget from 'components/forms/widgets/SemanticUpDownWidget'

import SemanticGeocoderWidget from 'components/forms/widgets/SemanticGeocoderWidget'

import pluralize from 'pluralize'

import history from 'custom_history'

import { fetchResources } from 'actions/resources';
import { connect } from 'react-redux'
import { Button, Message, Segment, Divider, Dropdown } from 'semantic-ui-react'
import { upsertEntity, fetchEntities } from 'actions/entities'

import { actions as formActions } from 'react-redux-form'
import { setSetting } from 'actions/settings'

import { entitiesRoute } from 'route_helpers'

import dotProp from 'dot-prop'

import { getSchema, getEntityName } from 'selectors/forms'

var initialized = false

class SchemaForm extends React.Component {

  componentDidMount() {
    this.props.fetchResources();
  }

  componentDidUpdate() {
    if(this.props.batch) {
      if (!initialized) {
        this.props.initializeForm(this.props.schema);
        initialized = true
      }
    } else {
      if (!initialized) {
        this.props.initializeForm(this.props.schema);
        initialized = true
      }
    }
  }

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

	submitForm() {
  	this.submitButton.click();
  }

  renderForm() {
    const customFields = {
      ObjectField: SemanticObjectField,
      SchemaField: SemanticSchemaField,
      StringField: SemanticStringField,
      BooleanField: SemanticBooleanField,
      NestedFormField: SemanticNestedFormField,
      SelectorField: SemanticSelectorField,
      ArrayField: SemanticArrayField,
      SliceField: SemanticSliceField,
      DossierEditor: DossierEditorContainer,
    }

    const customWidgets = {
      DateWidget: SemanticDateWidget,
      DateTimeWidget: SemanticDateTimeWidget,
      TimeDurationWidget: SemanticTimeDurationWidget,
      TextWidget: SemanticTextWidget,
      PriceWidget: SemanticPriceWidget,
      NumberWidget: SemanticNumberWidget,
      CheckboxWidget: SemanticCheckboxWidget,
      CheckboxDateWidget: SemanticCheckboxDateWidget,
      DraftWidget: SemanticDraftWidget,
      ColorWidget: SemanticColorWidget,
      SelectWidget: SemanticSelectWidget,
      UpDownWidget: SemanticUpDownWidget,
      ButtonSelectWidget: SemanticButtonSelectWidget,
      FileWidget: SemanticFileWidget,

      GeocoderWidget: SemanticGeocoderWidget,
    }

    const { model, entitiesName, entityName, entityId, formState, errors, readonly} = this.props;

    let isPending = (formState =="pending")
    let isSubmitted = (formState =="submitted")
    let isSubmitFailed= (formState =="submitFailed")

    return (<Segment basic loading={isPending}>
                <Form fields={customFields} widgets={customWidgets}  formContext={{entity: model, entityName: entityName, entityId: entityId, errors: errors}} FieldTemplate={SemanticFieldTemplate} className={`ui form ${isSubmitted ? "success" : ""} ${isSubmitFailed ? "failed" : ""}`}  {...{...{ onSubmit: () =>  { this.props.handleSubmit(this.props.afterSaveNavigation)}},...this.props}} model={model} autocomplete='off'>
                  { !this.props.readonly &&  this.props.SubmitButton &&  <button ref={(btn) => {this.submitButton=btn;}} style={{display: 'none'}}/> }
             { !this.props.readonly && !this.props.SubmitButton && this.renderStandardSubmitButton()  }
              </Form>
            </Segment>
      )
  }

  renderStandardSubmitButton() {
    const { model, entitiesName, entityName, entityId, formState, errors, skipSubmitDropdown } = this.props;

    const saveOptions = [
      { key: 'overview', icon: 'list', text: I18n.t('components.entities_list.overview'), value: 'overview' },
      { key: 'edit', icon: 'edit', text: I18n.t('components.entities_list.continue'), value: 'edit' },
    ]

    return [<Divider key={`divide_${entityId}`}/>, <Button.Group color='green' key={`button_group_${entityId}`}><Button key={`button_${entityId}`} primary>{I18n.t('components.schema_form.submit')}</Button>
      { !skipSubmitDropdown ?
      <Dropdown color='green' options={saveOptions} button value={this.props.afterSaveNavigation} onChange={(event, { value }) =>  this.props.handleAfterSaveNavigationChange(value)} upward>
      </Dropdown> : null }
    </Button.Group>]
  }

  renderSubmitButton() {
    const { SubmitButton, entityId, afterSaveNavigation, readonly } = this.props;
    if (SubmitButton && !readonly) {
      return <SubmitButton key={`button-${entityId}`} submit={this.submitForm} afterSaveNavigation={afterSaveNavigation}/>
    } else {
      return null
    }
  }

  render() {
    const { model, entitiesName, entityName, entityId, FormWrapper} = this.props;


    if(entityId) {
      return [FormWrapper ? <FormWrapper scrolling={true}>{this.renderForm()}</FormWrapper>  : this.renderForm(), this.renderSubmitButton()];
    } else {
      return (<div>No form</div>)
    }
  }
}

const upsertedRelationshipsFromUiSchema = (uiSchema) => {
  let collectedUpsertedRelationships = []
  Object.keys(uiSchema || {}).forEach( (fieldName) => {
    let field = uiSchema[fieldName]
    if (field) {
      if (field["ui:field"] == "NestedFormField") {
        collectedUpsertedRelationships.push(field["ui:options"]["entityName"])
        collectedUpsertedRelationships = collectedUpsertedRelationships.concat(upsertedRelationshipsFromUiSchema(field["ui:schema"]))
      } else if (field["items"] && (field["items"]["ui:field"] == "NestedFormField")) {

        collectedUpsertedRelationships.push(field["items"]["ui:options"]["entityName"])
        collectedUpsertedRelationships = collectedUpsertedRelationships.concat(upsertedRelationshipsFromUiSchema(field["items"]["ui:schema"]))
      }
    }
  })

  return collectedUpsertedRelationships
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const entityName = ownProps.entityName
  const entitiesName = pluralize(ownProps.entityName)
  const model = `entities.${entitiesName}.byId.${ownProps.entityId}`

  const uiSchema =  ownProps.uiSchema
  const upsertedRelationships = upsertedRelationshipsFromUiSchema(uiSchema)

  return {
    initializeForm: (schema) => {
      initializeProperties(dispatch, model, schema["properties"] || {})
    },
    fetchResources: () => {
      dispatch(fetchResources())
    },
    handleAfterSaveNavigationChange: (value) => {
      return dispatch(setSetting(`${entityName}AfterSaveNavigation`, value))
    },
    handleSubmit: (afterSaveNavigation) => {
      dispatch(upsertEntity(ownProps.entityName, ownProps.entityId, upsertedRelationships, ownProps.resourceName)).then( (entityId) => {
        if(ownProps.onSubmitSuccess) {
          ownProps.onSubmitSuccess(entityId)
        } else {
          if (afterSaveNavigation == 'overview') {
            history.push(entitiesRoute(entitiesName))
          }
        }

      })
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const entityName = getEntityName(state, ownProps)
  const entitiesName = pluralize(entityName)

  let model = `entities.${entityName}.byId.${ownProps.entityId}`

  const errorModel = `meta.${entityName}.errors.${ownProps.entityId}`
  const formModel = `meta.${entityName}.forms.${ownProps.entityId}`

  const errors = dotProp.get(state, errorModel)
  const form = dotProp.get(state, formModel)


  let afterSaveNavigation = state.settings[`${ownProps.entityName}AfterSaveNavigation`] || 'edit'

  return {
    afterSaveNavigation: afterSaveNavigation,
    schema: getSchema(entityName)(state, ownProps),
    errors: errors,
    entitiesName: entitiesName,
    model: model,
    formState: form && form.state
  }
}

const initializeProperties = (dispatch, model, properties) => {
  Object.keys(properties).forEach ( (propertyName) => {
    var property = properties[propertyName]
    if (property["type"] == "object") {
      initializeProperties(dispatch, `${model}.${propertyName}`, property["properties"])
    } else {
      dispatch(formActions.setInitial(`${model}.${propertyName}`))
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(SchemaForm);
