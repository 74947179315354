import React from 'react'

import { connect } from 'react-redux'

import { Segment, Header, Item, Button} from 'semantic-ui-react';

import { findEntity } from 'selectors/entities';

import EditorNode from 'components/forms/editor/EditorNode';

export class DossierSliceNode extends React.Component {
  constructor(props) {
    super(props)
    this.state = { collapsed: true }
  }

  render() {
    const { editor, children, isSelected, node, dossierSlice, attributes } = this.props
    const { collapsed } = this.state
    return (<div {...attributes}>
      <EditorNode structural={true} {...this.props} time={dossierSlice && dossierSlice.startTime} icon='user circle outline'>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false}>
          { "Dossier aangemaakt" }
        </Item>
      </EditorNode>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossierSlice = findEntity(state, 'dossierSlice', ownProps.sliceId)

  return {
    dossierSlice: dossierSlice
  }
}

export default connect(mapStateToProps)(DossierSliceNode)

