// This defines a HOC for ui based on redux-ui
//
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import invariant from 'invariant';
import { updateUI, mountUI } from 'actions/ui';

import dotProp from 'dot-prop-immutable';

class UI extends Component {

  constructor(props) {
    super(props);

    // If the key is undefined generate a new random hex key for the
    // current component's UI scope.
    //
    // We do this in construct() to guarantee a new key at component
    // instantiation time wihch is needed for iterating through a list of
    // components with no explicit key
    if (props.defaultKey === undefined) {
      this.key = (props.WrappedComponent.displayName ||
        props.WrappedComponent.name) +
      Math.floor(Math.random() * (1 << 30)).toString(16);
    } else {
      this.key = props.defaultKey;
    }

  }

  componentWillMount() {
    const uiProps = (this.props.ui && this.props.ui[this.key])
     if (uiProps === undefined) {
        this.props.mountUI(this.key, this.props.defaultState);
     }
  }

  updateUI(name, value) {
    this.props.updateUI(this.key, name, value);
  }

  render() {
    const uiProps = (this.props.ui && this.props.ui[this.key]) || {}
    const WrappedComponent = this.props.WrappedComponent

    return (<WrappedComponent { ...this.props } uiKey={ this.key } ui={uiProps} updateUI={ this.updateUI.bind(this)} />);
  }
}

export default function ui(opts = {}) {
  let defaultKey = opts.key;
  let defaultState = opts.state;

  const connector = connect(
    (state) => { return { ui: state.ui }; },
    (dispatch) => bindActionCreators({
      updateUI,
      mountUI,
    }, dispatch)
  );

  return (WrappedComponent) => {
    // Return a parent UI class which scopes all UI state to the given key
    return connector((props) => { return <UI WrappedComponent={WrappedComponent} {...props} defaultKey={defaultKey} defaultState={defaultState}/> });
  }
}
