import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'
import { healthFundSchema } from 'forms/admin/healthFundSchema'

class HealthFundForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <SchemaForm entityName='healthFund' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: healthFundSchema(state,ownProps),
    submitButton: true
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('countries', { admin: false}))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthFundForm);
