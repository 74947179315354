import React from 'react'

import { Menu, Sidebar, Icon } from 'semantic-ui-react';

import { Block } from 'slate'

import { DragSource } from 'react-dnd'

import InsertNodeItem from 'components/forms/editor/InsertNodeItem'

export default class VerticalAddBar extends React.Component {
  hasBlock = type => {
    const { editor } = this.props
    const { value } = editor
    return value.blocks.some(node => node.type === type)
  }

  renderBlockButton = (type, icon) => {
    const { editor } = this.props
    let isActive = this.hasBlock(type)

    if (['numbered_list', 'bulleted_list'].includes(type)) {
      const { value: { document, blocks } } = editor

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = this.hasBlock('list_item') && parent && parent.type === type
      }
    }

    return (
      <Menu.Item active={isActive} icon={icon} onClick={event => this.onClickBlock(event, type)}
      >
      </Menu.Item>
    )
  }

  onClickUndo = (event) => {
    const { editor } = this.props
    editor.command('undo')
  }

  onClickRedo = (event) => {
    const { editor } = this.props
    editor.command('redo')
  }

  onClickBlock = (event, type) => {
    event.preventDefault()

    const { editor } = this.props
    const { value } = editor
    const { document } = value

    // Handle everything but list buttons.
    if (type !== 'bulleted_list' && type !== 'numbered_list') {
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list_item')

      if (isList) {
        editor
          .setBlocks(isActive ? 'paragraph' : type)
          .unwrapBlock('bulleted_list')
          .unwrapBlock('numbered_list')
      } else {
        editor.command('setBlocks', isActive ? 'paragraph' : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list_item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor
          .setBlocks('paragraph')
          .unwrapBlock('bulleted_list')
          .unwrapBlock('numbered_list')
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted_list' ? 'numbered_list' : 'bulleted_list'
          )
          .wrapBlock(type)
      } else {
        editor.setBlocks('list_item').wrapBlock(type)
      }
    }
  }

  render() {
    const { editor, removeNode } = this.props
    const { value } = editor
    const { data } = value
    const { undos, redos } = data.toJS()

    return (
      <Menu vertical icon contentEditable={false}>
        <InsertNodeItem type='note' icon='sticky note' editor={editor}/>
        <InsertNodeItem type='attachment' icon='attach' editor={editor}/>
        { removeNode &&
          <Menu.Item onClick={removeNode}>
            <Icon name='trash'/>
          </Menu.Item>
          }
          { this.renderBlockButton('header_one', 'header') }
          { this.renderBlockButton('block_quote', 'quote right') }
          { this.renderBlockButton('bulleted_list', 'list ul') }
          { this.renderBlockButton('numbered_list', 'list ol') }

          { undos && undos.length > 0 &&
          <Menu.Item onClick={this.onClickUndo}>
            <Icon name='undo'/>
          </Menu.Item> }
          { redos && redos.length > 0 &&
            <Menu.Item onClick={this.onClickRedo}>
              <Icon name='redo'/>
            </Menu.Item>
            }
        </Menu>
    )
  }
}
