import React from 'react'
import ui from 'redux-ui'
import pluralize from 'pluralize'
import { Menu, Icon, Button } from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'
import { createEntityWithDefaults } from 'actions/entities'
import { connect } from 'react-redux'

class AddModal extends React.Component {

  handleAddSubmitSuccess = () => {
    this.props.updateUI('open', false)
    this.props.handleAddSubmitSuccess(this.props.ui.modalEntityId)
  }

  SubmitButton = (props) => {
    return(<Modal.Actions>
      <Button color='green' inverted onClick={ (event, data) => {props.submit()}} >
        <Icon name='checkmark' /> {I18n.t(`components.${this.props.entitiesName}_list.submit`, { defaults: [{scope: 'components.entities_list.submit' }] })}
      </Button>
    </Modal.Actions>)
  }

  render() {
    const {
      entityId,
      entityName,
      EntityForm
    } = this.props
    const entitiesName = pluralize(entityName)

    return (

      <Menu.Item onClick={() => { this.props.updateUI('open', true); let id = this.props.dispatch(createEntityWithDefaults(entityName)); this.props.updateUI('modalEntityId', id) }}>
         <Icon name='plus'/>
        <Modal size='fullscreen' open={this.props.ui.open} onClose={() => this.props.updateUI('open', false)}>
          <Modal.Header>{I18n.t(`components.${entitiesName}_selector.new`)}</Modal.Header>
          { this.props.ui.modalEntityId &&
            <EntityForm entityId={this.props.ui.modalEntityId} FormWrapper={Modal.Content} SubmitButton={this.SubmitButton} onSubmitSuccess={this.handleAddSubmitSuccess}/> }
        </Modal>
      </Menu.Item>)

  }

}

export default ui({state: { open: false, modalEntityId: null }})(connect()(AddModal));
