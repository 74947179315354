import orm from  'models'
import pluralize from 'pluralize'
import { singular }  from 'pluralize'
/* eslint no-use-before-define: [1, 'nofunc'] */

function uniqueId(objectName, id) {
  if (!id) {
    return null;
  }

  return `${objectName}${id}`;
}

function denormalizeRelationship(reducer, target, relationship, includedRelationships, options, cache) {
  const { ignoreLinks } = options;
  const rel = target.relationships[relationship];

  if (typeof rel.data !== 'undefined') {
    if (Array.isArray(rel.data)) {
      return rel.data.map(child => (denormalize(reducer, singular(child.type), child.id, includedRelationships, options, cache) || {}) || child);
    } else if (rel.data === null) {
      return null;
    }
    return (denormalize(reducer, singular(rel.data.type), rel.data.id, includedRelationships, options, cache) || {}) || rel.data;
  }

  return undefined;
}

export default function denormalize(reducer, objectName, id = null, includedRelationships = [], providedOpts = {}, cache = {},) {
  let session = orm.session(reducer)

  console.log("objectname")
  console.log(objectName)
  console.log(includedRelationships)
  console.log("filtered")
  console.log(includedRelationships.filter((includedRelationship) => { return (includedRelationship !== objectName) }))

  const defOpts = { ignoreLinks: false, includeType: false };
  const options = Object.assign({}, defOpts, providedOpts);
  const { includeType } = options;

  if (id === null || Array.isArray(id)) {
    const idList = id || reducer[objectName].allIds;

    return idList.map(e => denormalize(reducer, objectName, e, includedRelationships, options, cache));
  }

  const ids = id.toString();
  const uuid = uniqueId(objectName, ids);
  const cachedObject = cache[uuid];

  if (cachedObject) {
    return cachedObject;
  }

  const ret = { type: pluralize(objectName), data: {}, included: []};
  const target = session[objectName].withId(ids);

  const { ref: targetRef } = target;

  if (!target) {
    return null;
  }

  ret.data = {...{attributes: {}, relationships: {}},...{ type: pluralize(objectName), id: id}};

  cache[uuid] = ret;

  if (target.getClass().fields) {
    Object.keys(target.getClass().fields).forEach((fieldKey) => {
      let field = target.getClass().fields[fieldKey]
      if (field.toModelName) {
        // Belongs to
        if (includedRelationships.indexOf(field.toModelName) > -1) {
          if (targetRef[fieldKey]) {
            let denormalizedRelationship = denormalize(reducer, field.toModelName, targetRef[fieldKey], includedRelationships.filter((includedRelationship) => { return (includedRelationship !== objectName) }), options, cache)
            ret.data.relationships[field.as || field.toModelName] = { data: { id: targetRef[fieldKey], type: pluralize(field.toModelName) }}
            if (denormalizedRelationship.data) {
              ret.included.push(denormalizedRelationship.data);
            }
            ret.included = ret.included.concat(denormalizedRelationship.included);
          } else {
            console.log(`No field ${fieldKey} in ${JSON.stringify(targetRef)} `)
          }
        } else {
          ret.data.attributes[fieldKey] = targetRef[fieldKey]
        }
      } else {
        // Regular attribute
        ret.data.attributes[fieldKey] = targetRef[fieldKey]
      }
      }
    );
  }

  if (target.getClass().virtualFields) {
    Object.keys(target.getClass().virtualFields).forEach((fieldKey) => {
      let field = target.getClass().virtualFields[fieldKey]
      if (field.toModelName) {
        // Has many
        if (includedRelationships.indexOf(field.toModelName) > -1) {
          let hasManyEntities = (target[fieldKey].toRefArray() || [])
          ret.data.relationships[pluralize(field.toModelName)] = { }
          ret.data.relationships[pluralize(field.toModelName)].data = []
          hasManyEntities.forEach( (entity) => {
            let denormalizedRelationship = denormalize(reducer, field.toModelName, entity.id, includedRelationships.filter((includedRelationship) => { return (includedRelationship !== objectName) }), options, cache)
            ret.data.relationships[pluralize(field.toModelName)].data.push({ id: entity.id, type: pluralize(field.toModelName) })
            if (denormalizedRelationship.data) {
              ret.included.push(denormalizedRelationship.data);
            }
            ret.included = ret.included.concat(denormalizedRelationship.included);
          })
        }
      }
    });
  }

  if (typeof ret.id === 'undefined') {
    ret.id = ids;
  }

  return ret;
}
