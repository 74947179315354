import { flattenSchema } from 'flatten_entity'

import { createSelector } from 'reselect'

export const getUiSchema = (state,props) => {
  return props.uiSchema
}

export const getNestedUiSchema = (state,props) => {
  return props.uiSchema["ui:schema"]
}

export const getUiOptions = (state, props) => {
  return getUiSchema(state,props)["ui:options"]
}

export const getEntityNameFromOptions = (state,props) => {
  return getUiOptions(state,props).entityName
}

export const getEntityName = (state,props) => {
  return props.resourceName || props.entityName
}

export const getResources = (state) => { return state.resources.list }

export const getResource = (entityName) => {
  return createSelector([getResources] ,(resources) => {
    return resources && resources.find((resource) => {
      return (resource.attributes.title == entityName )
    })
  })
}

export const getSchema = (entityName) => {
  return createSelector([getResource(entityName)], (resource) => {
    return (resource && flattenSchema(resource.attributes.schema)) || {}
  })
}

