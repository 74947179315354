import { fk, attr } from 'redux-orm'
import Base from './Base'

class Contact extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      firstName: attr(),
      lastName: attr(),
      gender: attr(),
      email: attr(),
      birthday: attr(),
      phone: attr()
    }
  }
}

Contact.modelName = 'contact'

export default Contact;
