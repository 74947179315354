import React from 'react'
import { Sidebar, Button, Segment } from 'semantic-ui-react'
import ConsultationsListContainer from 'components/consultations/ConsultationsListContainer'
import ConsultationsMenuContainer from 'components/consultations/ConsultationsMenuContainer'
import Consultation from 'components/consultations/Consultation'
import EventsCalendarContainer from 'components/agenda/EventsCalendarContainer'
import { Switch, Route } from 'react-router-dom'

import { fetchEntities } from 'actions/entities';
import { connect } from 'react-redux'

class Agenda extends React.Component {
  state = { visible: true }

  componentDidMount () {
    this.props.fetchEntities();
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible })

  render () {
    return (
      <div>
        <EventsCalendarContainer/>
        <Switch>
          <Route path={Routes.consultation_path({id: ':id', _options: true })} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <ConsultationsMenuContainer keyWindow='eventsCalendar'/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Consultation consultationId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

         <Route path={Routes.consultations_path()} render={ () => <ConsultationsListContainer keyWindow='eventsCalendar'/>}/>
        </Switch>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('dossiers'))
      dispatch(fetchEntities('calendars'))
    }
  };
}

export default connect(null, mapDispatchToProps)(Agenda);
