import invoiceItemSchema from 'forms/invoiceItemSchema'

const invoiceSchema = function(state, props) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "invoiceDate": {
      "ui:options": {
        "width": 4
      }
    },
    "paid": {
    },
    "payedAt": {
      "ui:widget": "CheckboxDateWidget",
    },
    "dossierId": {
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "dossier",
        "displayAttribute": (dossier) => `${dossier.patient && dossier.patient.lastName} ${dossier.patient && dossier.patient.firstName}`
      }
    },
    "invoiceItems": {
      "ui:title": I18n.t('api.forms.invoice_item.title'),
      "ui:options": {
        "entityName": "invoiceItem",
        "displayTitle": false
      },
      "items" : {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "invoiceItem",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": invoiceItemSchema(state, props)
      },
    }
  }

  return uiSchema
}

export default invoiceSchema;

