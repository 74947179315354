import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { fetchEntities, createEntity, destroyEntity } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import ConsultationTypesListItem from 'components/admin/consultation_types/ConsultationTypesListItem'
import ConsultationTypeForm from 'components/admin/consultation_types/ConsultationTypeForm'

import { getConsultationTypes } from 'selectors/consultation_types'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getConsultationTypes(state,ownProps),
    entityName: 'consultationType',
    item: ConsultationTypesListItem,
    form: ConsultationTypeForm,
    listComponent: Card.Group,
    loading: state.meta.consultationType.loading,
    error: state.meta.consultationType.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultationTypes'))
    },
    upsertEntity: (entityId) => {
      dispatch(upsertEntity('consultationType', entityId))
    },
    createEntity: () => {
      dispatch(createEntity('consultationType'))
    },
    destroyEntity: (entityId) => {
      dispatch(destroyEntity('consultationType', entityId))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
