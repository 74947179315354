import AirbrakeClient from 'airbrake-js';

let airbrake = {}
if(process.env.NODE_ENV == 'production') {
  airbrake = new AirbrakeClient({
    projectId: "3ecdda69cdb8d7362adcea4ba7100f10",
    projectKey: "3ecdda69cdb8d7362adcea4ba7100f10",
    reporter: 'xhr',
    host: 'https://zorgmee-errbit.herokuapp.com'
  })
}

export default airbrake;
