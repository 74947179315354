import { fk, attr } from 'redux-orm'
import Base from './Base'

class EventConsultationTypeTag extends Base {
  static get fields() {
    return {
      id: attr(),
      strength: attr(),
      consultationTypeId :fk({to: 'consultationType', as: 'consultationType', relatedName: 'eventConsultationTypeTag'}),
    }
  }
}

EventConsultationTypeTag.modelName = 'eventConsultationTypeTag'

export default EventConsultationTypeTag;
