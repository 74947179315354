import { fk, attr } from 'redux-orm'
import Base from './Base'

class ConsultationReminder extends Base {
  static get fields() {
    return {
      id: attr(),
      resolvedAt: attr(),
      resolutionType: attr(),
      consultationId :fk({to: 'consultation', as: 'consultation', relatedName: 'consultationReminder'}),
      emailCommunicationId :fk({to: 'emailCommunication', as: 'emailCommunication', relatedName: 'consultationReminder'}),
    }
  }
}

ConsultationReminder.modelName = 'consultationReminder'

export default ConsultationReminder;
