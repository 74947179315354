import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import ConsultationTypesListContainer from 'components/admin/consultation_types/ConsultationTypesListContainer';
import ConsultationTypesMenuContainer from 'components/admin/consultation_types/ConsultationTypesMenuContainer';
import ConsultationTypeForm from 'components/admin/consultation_types/ConsultationTypeForm';

export default class ConsultationTypes extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.admin_consultation_type_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <ConsultationTypesMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <ConsultationTypeForm entityId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.admin_consultation_types_path()} component={ConsultationTypesListContainer}/>
        </Switch>
      </div>
    )
  }
}

