import { fk, attr } from 'redux-orm'
import Base from './Base'

class EventDossierTag extends Base {
  static get fields() {
    return {
      id: attr(),
      strength: attr(),
      dossierId :fk({to: 'dossier', as: 'dossier', relatedName: 'eventDossierTag'}),
    }
  }
}

EventDossierTag.modelName = 'eventDossierTag'

export default EventDossierTag;
