import addressSchema from 'forms/addressSchema'
import genderSchema from 'forms/genderSchema'

import ContactForm from 'components/contacts/ContactForm'

const contactSliceSchema = function(state, props) {
  const uiSchema = {
    "gender": {...genderSchema(state,props)},
    "startTime": {
      "ui:widget": "hidden",
    },
    "endTime": {
      "ui:widget": "hidden",
    },
    "phone": {
      "ui:group": 'email',
      "ui:options": {
        width: 6
      }
    },
    ...addressSchema(state,props)
  }

  return uiSchema
}

export default contactSliceSchema;
