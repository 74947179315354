import React from 'react'
import { connect } from 'react-redux'
import { Item, Header } from 'semantic-ui-react'
import { fetchEntities } from 'actions/entities';
import DossierFinancialForm from 'components/dossiers/DossierFinancialForm'
import DossierInvoicesListContainer from 'components/dossiers/DossierInvoicesListContainer'

class DossierFinancialInfo extends React.Component {
  render() {
    const dossier = this.props.dossier;

    if (dossier) {
      return (
        <div>
          <DossierFinancialForm entityId={dossier.id} dossier={dossier}/>
          <DossierInvoicesListContainer dossierId={dossier.id}/>
        </div>
      )
    } else {
      return (
        <Item>
          Geen dossier
        </Item>
      )
    }
  }
}


export default connect()(DossierFinancialInfo)
