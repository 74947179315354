import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { upsertEntity, createEntity, fetchEntities, refreshEntities } from 'actions/entities'
import { getSelectableCalendarByCalendarIdentification } from 'selectors/selectable_calendars'

import calendarSchema from 'forms/calendarSchema'

class CalendarForm extends React.Component {
  componentDidMount () {
    this.props.fetchSelectableCalendars();
  }

  componentDidUpdate() {
    this.props.syncColor(this.props.entityId, this.props.selectableCalendar)
  }

  render () {
    if (this.props.entityId) {
      return (
        <SchemaForm entityName='calendar' uiSchema={this.props.uiSchema} entityId={this.props.entityId} onSubmit={(event) => { this.props.upsertEntity(this.props.entityId)}} />
        )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: calendarSchema(state, ownProps),
    selectableCalendar: getSelectableCalendarByCalendarIdentification(state, ownProps),
    entityId: state.meta.calendar.edited
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    upsertEntity: (calendarId) => {
      dispatch(upsertEntity('calendar', calendarId)).then( () =>
        dispatch(refreshEntities('selectableCalendars'))
      )
    },
    fetchSelectableCalendars: () => {
      dispatch(fetchEntities('selectableCalendars'))
    },
    syncColor: (entityId, selectableCalendar) => {
      if (selectableCalendar) {
        dispatch(updateEntity('calendar', { id: entityId, color: selectableCalendar.color}))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarForm);
