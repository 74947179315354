import React from 'react'

import { Message, Icon } from 'semantic-ui-react';

export default class PlaceholderNode extends React.Component {
  render() {
    const { attributes } = this.props
    return <div {...attributes}>
      <Message icon>
        <Icon name='inbox'/>
        <Message.Content>
          <Message.Header>
            { I18n.t('components.placeholder_node.title') }
          </Message.Header>
          { I18n.t('components.placeholder_node.content') }
        </Message.Content>
      </Message>
    </div>
  }
}
