import React from 'react'
import { connect } from 'react-redux'
import { Card, Icon, Menu } from 'semantic-ui-react'

import { fetchEntities } from 'actions/entities';
import { fetchInvoicesGenerate } from 'actions/invoices';

import EntitiesList from 'components/entities/EntitiesList';
import InvoicesListItem from 'components/invoices/InvoicesListItem'
import InvoiceForm from 'components/invoices/InvoiceForm'
import { getSortedInvoices } from 'selectors/invoices'

import dotProp from 'dot-prop'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getSortedInvoices(state),
    entityName: 'invoice',
    item: InvoicesListItem,
    form: InvoiceForm,
    listComponent: Card.Group,
    loading: state.meta?.invoice?.loading,
    error: state.meta?.invoice?.error,
    totalPages: dotProp.get(state, 'meta.invoice.keyWindows.byWindow.list.totalPages'),
    activePage: dotProp.get(state, 'meta.invoice.keyWindows.byWindow.list.page'),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    extraActions: () => { return <Menu.Item onClick={e => {  dispatch(fetchInvoicesGenerate()) } }><Icon name='repeat'/></Menu.Item> },
    fetchExtraEntities: () => {
      dispatch(fetchEntities('invoices', { name: 'list', page: 1, append: true }))
      dispatch(fetchEntities('dossiers'))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('locations'))
      dispatch(fetchEntities('emailCommunications'))
    },
    onLoadMore: (activePage, totalPages) => {
      if( activePage < totalPages ) {
        dispatch(fetchEntities('invoices', { name: 'list', filter: {}, page: activePage + 1, append: true }))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
