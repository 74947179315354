import React from 'react'

import DropzoneComponent from 'react-dropzone-component'

import { Grid, Header, Button, Icon, Menu, Segment, Divider, Label, Sticky } from 'semantic-ui-react';

import { DirectUpload } from '@rails/activestorage'

import EditorNode from 'components/forms/editor/EditorNode';

export default class AttachmentNode extends React.Component {

  removeNode = () => {
    const { editor, node } = this.props
    editor.command('removeNodeByKey', node.key)
  }

  render() {
    const { editor, node, children, isSelected, attributes } = this.props

    const componentConfig = {
        iconFiletypes: ['.jpg', '.png'],
        showFiletypeIcon: true,
        postUrl: 'no-post',
        autoProcessQueue: false
    };

    const eventHandlers = {
    };

    const djsConfig = {
      addRemoveLinks: true,
      paramName: this.props.paramName,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
      },
      maxFilesize: 1,
      dictDefaultMessage: I18n.t("components.semantic_file_widget.dict_default_message"),
      dictFallbackMessage: I18n.t("components.semantic_file_widget.dict_fallback_message"),
      dictFallbackText: I18n.t("components.semantic_file_widget.dict_fallback_text"),
      dictFileTooBig: I18n.t("components.semantic_file_widget.dict_file_too_big"),
      dictInvalidFileType: I18n.t("components.semantic_file_widget.dict_invalid_file_type"),
      dictResponseError: I18n.t("components.semantic_file_widget.dict_response_error"),
      dictCancelUpload: I18n.t("components.semantic_file_widget.dict_cancel_upload"),
      dictCancelUploadConfirmation: I18n.t("components.semantic_file_widget.dict_cancel_upload_confirmation"),
      dictRemoveFile: I18n.t("components.semantic_file_widget.dict_remove_file"),
      dictMaxFilesExceeded: I18n.t("components.semantic_file_widget.dict_max_files_exceeded"),
      addRemoveLinks: false

    }

    return <EditorNode icon={'attach'} type={'attachment'} {...this.props}>
      <DropzoneComponent config={componentConfig} djsConfig={djsConfig} eventHandlers={eventHandlers}/>
    </EditorNode>
  }
}
