import { DESTROY_FLASH } from 'actions/flash'

const initialState = {
  messages: [
  ]
}

export default function flash(state = initialState, action) {
  switch (action.type) {
  case DESTROY_FLASH:
    const filteredMessages = state.messages.filter( (message) => message.key != action.messageKey, this)

    return { ...state, messages: filteredMessages };
  }
  return state
}
