import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Button, Icon, Menu, Segment, Divider, Label, Sticky, Rail, Ref } from 'semantic-ui-react';

import { connect } from 'react-redux'

import { Range, Block } from 'slate'

import DossierItemsSerializer from 'slate-dossier-items-serializer'

import _ from 'lodash'

import { Editor } from 'slate-react'
import { Value, Selection, Data } from 'slate'

import NoteNode from 'components/forms/editor/NoteNode'
import PlaceholderNode from 'components/forms/editor/PlaceholderNode'
import AttachmentNode from 'components/forms/editor/AttachmentNode'
import DossierNode from 'components/forms/editor/DossierNode'
import DossierSliceNode from 'components/forms/editor/DossierSliceNode'
import EditorCore from 'components/forms/editor/EditorCore'
import ConsultationNode from 'components/forms/editor/ConsultationNode'
import VerticalAddBar from 'components/forms/editor/VerticalAddBar'

import ScrollContext from 'contexts/ScrollContext';

import { fromJS, Map } from 'immutable'

const editorSchema = {
  document: {
    nodes: [
     { match: [
        { type: 'note' },
        { type: 'attachment' },
        { type: 'consultation'},
        { type: 'dossier' },
        { type: 'slice' },
        { type: 'placeholder' }]
    }],
  },
  blocks: {
    placeholder: {
      isVoid: true
    },
    // placeholder: {
    //   nodes: [{
    //     match: [{ type: 'text'}], min: 1, max: 1
    //   }],
    //   text: /^placeholder$/,
    //   normalize: (editor, error) => {
    //     if (error.code == 'node_text_invalid') {
    //         console.log("error")
    //         console.log(error)
    //         console.log(error.node.toJS())
    //         console.log("text")
    //         console.log(error.node.getText())
    //         // editor.removeTextByKey(error.node.key, 0, error.node.getText().length)
    //         console.log("removedText")
    //         // editor.insertTextByKey(error.node.key, 0, 'placeholder')
    //         console.log("insertedText")
    //     }
    //   }
    // },
    dossier: {
      nodes: [{
        match: [{ type: 'note'}, { type: 'attachment' }, {type: 'placeholder' }], min: 1
      }],
      normalize: (editor, error) => {
        if (error.code == 'child_type_invalid') {
          if (error.child.object == 'text'){
            editor.wrapBlockByKey(error.child.key, { type: 'placeholder', data: error.node.data.toJS() })
          }
        }
        return true
      }
    },
    consultation: {
      nodes: [{
        match: [{ type: 'note'}, { type: 'attachment' }, {type: 'placeholder' }], min: 1
      }],
      normalize: (editor, error) => {
        if (error.code == 'child_type_invalid') {
          if (error.child.object == 'text'){
            editor.wrapBlockByKey(error.child.key, { type: 'placeholder', data: error.node.data.toJS() }) } }
      }
    },
    note: {
      nodes: [{
        match: [{ type: 'paragraph'}, { type: "block_quote"},{ type: "bulleted_list"}, { type: "header_one"}, { type: "header_two"}, { type: "header_three"}, {type: "header_four" }, { type: "header_five" }, { type: "header_six"} , { type: "list_item" } ], min: 1
      }],
      normalize: (editor, error) => {
        if (error.code == 'child_type_invalid') {
          if (error.child.object == 'text') {
            editor.wrapBlockByKey(error.child.key, { type: 'paragraph', data: error.node.data.toJS() })
          }
          if (error.child.type == 'note') {
            editor.setNodeByKey(error.child.key, { type: 'paragraph' })
          }
        }
      }
    }
  }
}

class DossierEditor extends React.Component {

  constructor(props) {
    super(props)
    this.serializer = (new DossierItemsSerializer)
    this.editorRef = React.createRef()
    this.addBarRef = React.createRef()

  }

  /**
   * On key down, check for our specific key shortcuts.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Function} next
   */

  onKeyDown = (event, editor, next) => {
    switch (event.key) {
      case ' ':
        return this.onSpace(event, editor, next)
      case 'Backspace':
        return this.onBackspace(event, editor, next)
      case 'Enter':
        if (event.shiftKey) {
          event.preventDefault()

          const { value } = editor
          const { startBlock } = value
          editor.insertBlock({ type: 'note', data: startBlock.data.toJS()})
        } else {
          return this.onEnter(event, editor, next)
        }
      default:
        return next()
    }
  }

  getType = chars => {
    switch (chars) {
      case '*':
      case '-':
      case '+':
        return 'list_item'
      case '>':
        return 'block_quote'
      case '#':
        return 'header_one'
      case '##':
        return 'header_two'
      case '###':
        return 'header_three'
      case '####':
        return 'header_four'
      case '#####':
        return 'header_five'
      case '######':
        return 'header_six'
      default:
        return null
    }
  }

  /**
   * On space, if it was after an auto-markdown shortcut, convert the current
   * node into the shortcut's corresponding type.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Function} next
   */

  onSpace = (event, editor, next) => {
    console.log("Spaced")

    const { value } = editor
    const { selection } = value
    if (selection.isExpanded) return next()

    const { startBlock } = value
    const { start } = selection
    const chars = startBlock.text.slice(0, start.offset).replace(/\s*/g, '')
    const type = this.getType(chars)
    if (!type) {
      return next()
    }

    if (type === 'list_item' && startBlock.type === 'list_item') {
      return next()
    }

    console.log("ok we have a type")
    console.log(type)
    console.log("selection")
    console.log(selection.toJS())
    event.preventDefault()

    editor.setBlocks({ type: type, data: startBlock.data.toJS() })

    console.log("value after set block")
    console.log(editor.value.toJS())

    if (type === 'list_item') {
      editor.wrapBlock({ type: 'bulleted_list', data: startBlock.data.toJS()})
    }

    editor.moveFocusToStartOfNode(startBlock).delete()

    console.log("value after apply")
    console.log(editor.value.toJS())
  }

  /**
   * On backspace, if at the start of a non-paragraph, convert it back into a
   * paragraph node.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Function} next
   */

  onBackspace = (event, editor, next) => {
    console.log("Backspaced")

    const { value } = editor
    const { selection } = value
    if (selection.isExpanded) return next()
    if (selection.start.offset !== 0) return next()

    const { startBlock } = value
    if (startBlock.type === 'paragraph') return next()

    event.preventDefault()
    editor.setBlocks({ type: 'paragraph', data: startBlock.data.toJS() })

    if (startBlock.type === 'list_item') {
      editor.unwrapBlock('bulleted_list')
    }
  }

  /**
   * On return, if at the end of a node type that should not be extended,
   * create a new paragraph below it.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Function} next
   */

  onEnter = (event, editor, next) => {
    console.log("Entered")

    const { value } = editor
    const { selection } = value
    const { start, end, isExpanded } = selection
    if (isExpanded) {
      return next()
    }

    const { startBlock } = value
    if (start.offset === 0 && startBlock.text.length === 0) {
      return this.onBackspace(event, editor, next)
    }

    if (end.offset !== startBlock.text.length) {
      return next()
    }

    if (
      startBlock.type !== 'header_one' &&
      startBlock.type !== 'header_two' &&
      startBlock.type !== 'header_three' &&
      startBlock.type !== 'header_four' &&
      startBlock.type !== 'header_five' &&
      startBlock.type !== 'header_six' &&
      startBlock.type !== 'block_quote'
    ) {
      return next()
    }

    event.preventDefault()
    editor.splitBlock().setBlocks({ type: 'paragraph', data: startBlock.data.toJS() })
  }

  renderNode = (props,next) => {
    const { node, attributes, children } = props

    switch(node.type) {
      case 'block_quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted_list':
        return <ul {...attributes}>{children}</ul>
      case 'numbered_list':
        return <ol {...attributes}>{children}</ol>
      case 'header_one':
        return <h1 {...attributes}>{children}</h1>
      case 'header_two':
        return <h2 {...attributes}>{children}</h2>
      case 'header_three':
        return <h3 {...attributes}>{children}</h3>
      case 'header_four':
        return <h4 {...attributes}>{children}</h4>
      case 'header_five':
        return <h5 {...attributes}>{children}</h5>
      case 'header_six':
        return <h6 {...attributes}>{children}</h6>
      case 'paragraph':
        return <p {...attributes}>{children}</p>
      case 'list_item':
return <li {...attributes}>{children}</li>
      case 'consultation':
        return <ConsultationNode key={node.key} consultationId={node.toJS().data.consultationId} {...props}/>
      case 'slice':
        return <DossierSliceNode key={node.key} sliceId={node.toJS().data.sliceId} {...props}/>
      case 'dossier':
        return <DossierNode key={node.key} dossierId={node.toJS().data.dossierId} {...props}/>
      case 'note':
        return <NoteNode {...props}/>
      case 'placeholder':
        return <PlaceholderNode {...props}/>
      case 'attachment':
        return <AttachmentNode {...props}/>
      default:
        return next()
    }
  }


  renderEditor =  (props, next) => {
    const { editor } = props
    const children = next()
    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column width={15}>
            {/* <Menu secondary borderless> */}
            {/*   <Menu.Item icon='sticky note' onClick={() => this.insertNote(props.editor)}> */}
            {/*   </Menu.Item> */}
            {/* </Menu> */}
            <EditorCore content={children}/>
          </Grid.Column>
          <Ref innerRef={this.contextRef}>
            <Grid.Column width={1}>
              <ScrollContext.Consumer>
                { scrollContext => {
                  return (
                  <Sticky scrollContext={scrollContext.current}>
                    <VerticalAddBar editor={editor}/>
                  </Sticky>)
                  }
                }
               </ScrollContext.Consumer>
            </Grid.Column>
          </Ref>
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const { onChange, json, ui } = this.props

    let fullJson = {...json, ...{selection: ui.selection}}
    console.log("fullJson")
    console.log(fullJson)

    let value = Value.fromJSON(fullJson)

    return(
      <Editor schema={editorSchema} value={value} onChange={
        (change) => { onChange(change, this.props) }
      } renderNode={this.renderNode} renderEditor={this.renderEditor} onKeyDown={this.onKeyDown} ref={this.editorRef}/>
    )
  }
}



export default DossierEditor

