import AppChannels from 'cable.js'
import store from 'store.js'
import moment from 'moment'
import { refreshEntities } from 'actions/entities'
import { setProgress, endProgress } from 'actions/progress'
import { setChannelConnected, setChannelDisconnected} from 'actions/channels'


export default function subscribeCalendarSync(calendarId) {
  if (process.env.NODE_ENV === 'production') {
    AppChannels.calendarSyncs = AppChannels.calendarSyncs || {};

    if(!AppChannels.calendarSyncs[calendarId]) {
      AppChannels.calendarSyncs[calendarId] = AppChannels.cable.subscriptions.create({ channel: "CalendarSyncChannel", id: calendarId }, {
        connected: function() {
          store.dispatch(setChannelConnected('calendar_sync'))
        },

        disconnected: function() {
          store.dispatch(setChannelDisconnected('calendar_sync'))
        },

        received: function(data) {
          switch (data.status) {
            case 'sync_started':
              break;
            case 'sync_running':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`), data.color));
              break;
            case 'syncing':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`), data.color));
              break;
            case 'ok':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`), data.color));
              store.dispatch(endProgress(`sync_${data.calendar_sync_id}`));
              if(store.getState().ui.eventsCalendar) {
                let date = store.getState().ui.eventsCalendar.date
                let view = store.getState().ui.eventsCalendar.view
                let startTime = moment(date).startOf(view).toISOString()
                let endTime = moment(date).endOf(view).toISOString()
                store.dispatch(refreshEntities('events', { name: 'eventsCalendar', filter: { startTime: startTime, endTime: endTime}, page: 1 }));
              }
              // Called when there's incoming data on the websocket for this channel
            case 'failed':
              store.dispatch(endProgress(`sync_${data.calendar_sync_id}`));
              if(store.getState().ui.eventsCalendar) {
                let date = store.getState().ui.eventsCalendar.date
                let view = store.getState().ui.eventsCalendar.view
                let startTime = moment(date).startOf(view).toISOString()
                let endTime = moment(date).endOf(view).toISOString()
                store.dispatch(refreshEntities('events', { name: 'eventsCalendar', filter: { startTime: startTime, endTime: endTime}, page: 1 }));
              }
              break;
          }


        }
      });
    }
  }
}
