import React from 'react'

import { Grid, Header, Button, Icon, Menu, Segment, Divider, Label, Sticky } from 'semantic-ui-react';

import AddBar from 'components/forms/editor/AddBar';
import Timeline from 'components/forms/editor/Timeline';

import { DragSource, DropTarget } from 'react-dnd';

import { getEmptyImage } from 'react-dnd-html5-backend';

import EditorNodeDrop from 'components/forms/editor/EditorNodeDrop'

class EditorNode extends React.Component {

  removeNode = () => {
    const { editor, node } = this.props
    editor.command('removeNodeByKey', node.key)
  }

  renderCore() {
    const { editor, node, children, isSelected, attributes, connectDragSource, connectDragPreview, isDragging , connectDropTarget, isOver, icon, type, structural } = this.props
    return <React.Fragment>
      {!isDragging &&
      <Segment basic style={{paddingTop: '1.5em', paddingLeft: '1.5em'}} className={`editor-node ${type} ${structural ? "structural" : "editorial"}`}>
        { !structural &&  <Icon name={icon} className="editor-node-icon"/> }
        { children }
        { !structural && <Icon name='trash' className='editor-node-remove-icon button' onClick={this.removeNode}/> }
      { connectDragPreview(getEmptyImage()) }
    </Segment> }
    { isOver && <EditorNodeDrop node={node} structural={structural}/> }
    </React.Fragment>
  }

  render() {
    const { editor, node, children, isSelected, attributes, connectDragSource, connectDragPreview, isDragging , connectDropTarget, isOver, icon, type, structural, time } = this.props

    let first = false
    let last = false
    if (structural) {
      last = !editor.value.document.getNextSibling(node.key)
      first = !editor.value.document.getPreviousSibling(node.key)
    }

    return connectDropTarget(connectDragSource(
      <div {...attributes}>
        { structural ? <Timeline icon={icon} last={last} first={first} time={time}>{this.renderCore()}</Timeline> : this.renderCore() }
  </div>))
  }
}

let moveNode = (props, key) => {
  const { editor, node } = props
  let block = editor.value.document.getNode(key)
  if (block) {
    let parent = editor.value.document.getParent(block.key)
    let index = parent.nodes.map( (node) => { return node.key}).indexOf(key)
    editor.command('moveNodeByKey', node.key, parent.key, index + 1)
  } else {
    console.log("failed move")
  }
}

let dropSpec = {
  hover: (props,monitor, component) => {
  },
  drop: (props,monitor, component) => {
  }
}

let dropCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

let dragSpec = {
  beginDrag: (props, monitor, component) => { return { key: props.node.key, node: props.node }
  },
  endDrag: (props, monitor, component) => {
    let dropResult = monitor.getDropResult()
    if (dropResult) {
      moveNode(props, dropResult.key)
    }
  }
}

let dragCollect = (connect, monitor) => {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  }
}


export default DropTarget('editor-node', dropSpec, dropCollect)(DragSource('editor-node', dragSpec, dragCollect)(EditorNode))
