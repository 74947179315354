import React from 'react'
import {Grid, Header, Segment, Button, Form, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Flash from "./generics/Flash"
import store, { persistor } from 'store.js'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

export default class Login extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Grid verticalAlign="middle" textAlign="center" relaxed centered columns={1}>
          <Grid.Column tablet={10} mobile={16} computer={6}>
            <Header textAlign="center" size="huge">
              {I18n.t('components.login.header')}
            </Header>
            <Form>
            </Form>
            <Segment>
              <Flash/>
              <a href={Routes.user_google_oauth2_omniauth_authorize_path()}>
                <Button fluid={true}>
                  {I18n.t('components.login.login_with_google')}
                </Button>
              </a>
            </Segment>
          </Grid.Column>
        </Grid>
      </Provider>
    )
  }
}

