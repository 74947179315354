import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'


import { getFilteredDossiers } from 'selectors/dossiers'
import DossierForm from 'components/dossiers/DossierForm'
import {  createEntityWithDefaults } from 'actions/entities';

const mapStateToProps = (state, ownProps) => {

  return {
    entities: getFilteredDossiers(state),
    entityName: 'dossier',
    form: DossierForm,
    item: (props) => <div>{`${props.dossier.patient?.lastName} ${props.dossier.patient?.firstName}`}</div>,
    loading: state.meta.dossier?.loading,
    error: state.meta.dossier?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('dossiers'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('dossiers', filter))
    },
    createEntityWithDefaults: () => {
      let contactId = dispatch(createEntityWithDefaults('contact'))
      let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
      let dossierId = dispatch(createEntityWithDefaults('dossier', { patientId: contactId }))
      let dossierSliceId = dispatch(createEntityWithDefaults('dossierSlice', { dossierId: dossierId}))
      return dossierId
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
