const generateConsultationErrorSchema = function(state, props) {
  const uiSchema = {
    "resolutionType" : {
      "ui:widget": "hidden"
    },
    "matchId": {
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "dossier",
        "displayAttribute": (dossier) => `${dossier.patient.firstName} ${dossier.patient.lastName}`,
      }
    }
  }

  return uiSchema
}

export default generateConsultationErrorSchema;
