import React from 'react'

import { Menu, Icon } from 'semantic-ui-react';

import { Block } from 'slate'

import { DragSource } from 'react-dnd'

import { getEmptyImage } from 'react-dnd-html5-backend';

class InsertNodeItem extends React.Component {

  insertNodeAtSelection = () => {
    const { editor } = this.props
    let block =  editor.value.document.getClosestBlock(editor.value.selection.start.key)
    insertNode(this.props, block.key)
  }

  render() {
    const { icon, connectDragSource, connectDragPreview, editor } = this.props
    return connectDragSource(<div>
      <Menu.Item icon={icon} onClick={() => this.insertNodeAtSelection()}>
    </Menu.Item>
      { connectDragPreview(getEmptyImage()) }
    </div>
    )
  }
}

let insertNode = (props, key) => {
  const { editor, type } = props
  console.log("inserting")
  console.log(key)
  let block =  editor.value.document.getNode(key)
  if (block) {
    editor.command('insertNodeByKey', block.key, 0,  Block.create({ type: type, data: block.toJS().data }) )
    console.log("inserted")
  } else {
    console.log("no block")
  }
}


let insertNodeAfter = (props, key) => {
  const { editor, type } = props
  let parent = editor.value.document.getParent(key)
  console.log("appending")
  let index = parent.nodes.map( (node) => { return node.key}).indexOf(key)
  editor.command('insertNodeByKey', parent.key, index + 1,  Block.create({ type: type , data: parent.data}) )
}

let dragSpec = {
  beginDrag: (props, monitor, component) => {
    return {
      key: 'new'
    }
  },
  endDrag: (props, monitor, component) => {
    let dropResult = monitor.getDropResult()
    if (dropResult) {
      if (dropResult.action == 'insertAfter') {
        insertNodeAfter(props, dropResult.key)
      } else {
        insertNode(props, dropResult.key)
      }
    }
  }
}

let dragCollect = (connect, monitor) => {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  }
}

export default DragSource('editor-node', dragSpec, dragCollect) (InsertNodeItem)

