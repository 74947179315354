import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from 'moment';

import underscored from 'underscore.string/underscored'

import { Grid, Header, Button, Icon, Menu, Accordion, Divider } from 'semantic-ui-react';

import { copyEntity, createEntity, deleteEntity, updateEntity } from 'actions/entities';

import ui from 'redux-ui'
import { connect } from 'react-redux'

import _ from 'lodash'

import { actions } from 'react-redux-form'
import dotProp from 'dot-prop'

import pluralize from 'pluralize'

import TimelinePicker from 'components/generics/TimelinePicker'

import { findEntity, getEntitiesByIds } from 'selectors/entities'

import {
  getWidget,
  getDefaultFormState,
  getUiOptions,
  isMultiSelect,
  isFilesArray,
  isFixedItems,
  allowAdditionalItems,
  optionsList,
  retrieveSchema,
  toIdSchema,
  getDefaultRegistry,
} from "react-jsonschema-form/lib/utils";

import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'

function SliceItem(props) {
  const {
    index,
    canRemove = true,
    name,
    itemSchema,
    itemData,
    itemUiSchema,
    itemIdSchema,
    itemErrorSchema,
    autofocus,
    onBlur,
    onFocus,
    sliceModel,
    selectedValueModel,
    formContext,
    disabled,
    readonly,
    registry
  } = props;

  const { fields: { SchemaField } } = registry;
  const { orderable, removable } = {
    orderable: true,
    removable: true,
    ...itemUiSchema["ui:options"],
  };
  const has = {
    remove: removable && canRemove,
  };
  has.toolbar = Object.keys(has).some(key => has[key]);

  return (<Grid.Row key={props.sortingIndex}>
    <Grid.Column width={16}>
      <SchemaField
        schema={itemSchema}
        uiSchema={itemUiSchema}
        errorSchema={itemErrorSchema}
        idSchema={itemIdSchema}
        onBlur={onBlur}
        onFocus={onFocus}
        registry={registry}
        disabled={disabled}
        readonly={readonly}
        autofocus={autofocus}
        model={sliceModel}
        name={name}
        formContext={formContext}
      />
    </Grid.Column>
  </Grid.Row>)
}

class SemanticSliceField extends ObjectField {
  static defaultProps = {
    uiSchema: {},
    sortedItems: [],
    idSchema: {},
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
  };

  render() {
    const {
      schema,
      uiSchema,
      idSchema,
      registry = getDefaultRegistry(),
    } = this.props;
    const { definitions } = registry;
    if (!schema.hasOwnProperty("items")) {
      return (<div>Unsupported</div>
      );
    }
    return this.renderSlice();
  }

  renderSlice() {
    const {
      schema,
      uiSchema,
      sortedItems,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      formContext,
      onBlur,
      sliceModel,
      selectedValueModel,
      selectedValue,
      selectedSlice,
      lastSlice,
      rawLastSlice,
      addable,
      newable,
      onFocus,
      selectItem,
      addSlice,
      createSlice,
      ui,
      updateUI
    } = this.props;
    const title = schema.title === undefined ? name : schema.title;
    const { SliceFieldTemplate, definitions, fields } = registry;
    const { TitleField, DescriptionField } = fields;
    const itemsSchema = retrieveSchema(schema.items, definitions);

    const itemSchema = retrieveSchema(schema.items, definitions, selectedSlice);
    const itemErrorSchema = errorSchema ? errorSchema[selectedValue] : undefined;
    const itemIdPrefix = idSchema.$id + "_" + selectedValue;
    const itemIdSchema = toIdSchema(
      itemSchema,
      itemIdPrefix,
      definitions,
      selectedSlice
    );

    const fieldItemProps = {
      selectedValue,
      name: `${selectedSlice?.id}.id`,
      itemSchema: itemSchema,
      itemIdSchema,
      itemErrorSchema,
      itemData: selectedSlice,
      itemUiSchema: uiSchema.items,
      autofocus: autofocus && index === 0,
      sliceModel,
      selectedValueModel,
      onBlur,
      onFocus,
      registry
    }

    let timelinePickerOptions = []

    sortedItems.forEach( (item, index) => {
      if (item.endTime) {
        timelinePickerOptions.push({ value: item.id,  leftLabel: I18n.l("date.formats.default", item.startTime), rightLabel: I18n.l("date.formats.default", item.endTime)})
      } else {
        timelinePickerOptions.push({ value: item.id, rightLabel: '...', leftLabel: I18n.l("date.formats.default", item.startTime)})
      }
    })

    return ( <React.Fragment>
        <div className='ui divider'/>
          { selectedSlice && <SliceItem key={selectedSlice.id} {...fieldItemProps}/> }
          { !selectedSlice && I18n.t('generic.empty') }
          { newable &&
            <div><Button basic icon='add' onClick= { (event) => {
            event.preventDefault();
            createSlice(selectedValueModel, this.props.entityName, formContext.entityName, formContext.entityId)
          } }/></div> }
        <Accordion>
          <Accordion.Title active={ui.showHistory} index={0} onClick={() => {  updateUI('showHistory', !ui.showHistory); selectItem(selectedValueModel,lastSlice.id)} }>
              <Icon name='dropdown' />
              { I18n.t('components.semantic_slice_field.history') }
            </Accordion.Title>
            <Accordion.Content active={ui.showHistory}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={14} >
                    <TimelinePicker selectedValue={selectedValue} options={timelinePickerOptions} onChange={ (value) => {
                      selectItem(selectedValueModel, value)
                    } }/>
                  </Grid.Column>
                  <Grid.Column width={2} >
                    { addable &&
                      <Button basic icon='add' onClick= { (event) => {
                      event.preventDefault();
                      addSlice(selectedValueModel, rawLastSlice, this.props.entityName)
                    } }/> }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </React.Fragment>
      );

  }

}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectItem: (selectedValueModel, value) => {
      dispatch(actions.change(selectedValueModel, value))
    },
    deleteEntity: (relationshipEntityName, relationshipEntityId) => {
      dispatch(deleteEntity(relationshipEntityName, relationshipEntityId))
    },
    addSlice: (selectedValueModel, rawLastSlice, entityName) => {
      let entityId = dispatch(copyEntity(entityName, rawLastSlice.id))

      dispatch(updateEntity(entityName, { id: entityId, startTime: moment().utc().endOf('day').toISOString() }))
      dispatch(updateEntity(entityName, { id: rawLastSlice.id, endTime: moment().utc().subtract(1, 'days').endOf('day').toISOString() }))

      dispatch(actions.change(selectedValueModel, entityId))
    },
    createSlice: (selectedValueModel,entityName, parentEntityName, parentEntityId) => {
      let entityId = dispatch(createEntity(entityName, { id: entityId, startTime: moment().utc().endOf('day').toISOString(), [`${parentEntityName}Id`]: parentEntityId }))

      dispatch(actions.change(selectedValueModel, entityId))
    },
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityName = ownProps.uiSchema["ui:options"].entityName
  var entitiesName = pluralize(entityName)

  var copiedRelationships = ownProps.uiSchema["ui:options"].copiedRelationships

  let parent = findEntity(state, ownProps.formContext.entityName, ownProps.formContext.entityId)

  var sliceModel = `entities.${entityName}.byId`

  var items = []

  if (parent) {
    items = parent[entitiesName]?.toRefArray().filter( (item) => { return (item.deleted !== "1")})
  }

  const sortedItems = (items && items.map( (item,index) => { return {...item, ...{trackingIndex:  index}}}).sort( (firstItem, secondItem) => { return (new Date(firstItem.startTime) - new Date(secondItem.startTime)) })) || []

  const lastSlice = (sortedItems.length > 0) && sortedItems[sortedItems.length - 1]
  let rawLastSlice = null
  if (lastSlice) {
    rawLastSlice = dotProp.get(state, `entities.${entityName}.byId.${lastSlice.id}`)
  }

  var selectedValueModel = `entities.${ownProps.formContext.entityName}.byId.${ownProps.formContext.entityId}.selected${entityName}`
  const selectedValue = dotProp.get(state, selectedValueModel) || (lastSlice && lastSlice.id)

  const selectedSlice = (sortedItems.length > 0) && sortedItems.find( (item) => { return item.id == selectedValue });

  const addable = (selectedSlice && lastSlice && (selectedSlice.id == lastSlice.id)) && !(lastSlice.startTime == moment().utc().startOf('day').toISOString())

  const newable = (sortedItems.length == 0)

  return {
    selectedValue: selectedValue,
    selectedSlice: selectedSlice,
    entityName: entityName,
    sliceModel: sliceModel,
    selectedValueModel: selectedValueModel,
    sortedItems: sortedItems,
    lastSlice: lastSlice,
    rawLastSlice: rawLastSlice,
    copiedRelationships: copiedRelationships,
    addable: addable,
    newable: newable
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ui({state: { showHistory: false }})(SemanticSliceField));
