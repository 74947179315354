import contactSchema from 'forms/contactSchema'
import dossierSliceSchema from 'forms/dossierSliceSchema'

const dossierSchema = function(state, props) {
  return {
    "ui:readonly": props.readonly,
    "patientId": {
      "ui:field": "NestedFormField",
      "ui:options": {
        "entityName": "contact",
        "titleKey": "components.dossier_form.patient"
      },
      "ui:schema": contactSchema(state,props)
    },
    "dossierSlices": {
      "ui:field": "SliceField",
      "ui:options": {
        "entityName": "dossierSlice",
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "dossierSlice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": dossierSliceSchema(state,props, 'dossier')
      }
    }
  }
}

export default dossierSchema;
