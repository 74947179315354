import React from 'react'
import ui from 'redux-ui'
import pluralize from 'pluralize'
import { Menu, Icon, Button} from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'

class EditModal extends React.Component {

  handleEditSubmitSuccess = () => {
    this.props.updateUI('open', false)
  }

  SubmitButton = (props) => {
    return(<Modal.Actions>
      <Button color='green' inverted onClick={ (event, data) => {props.submit()}} >
        <Icon name='checkmark' /> {I18n.t(`components.${this.props.entitiesName}_list.submit`, { defaults: [{scope: 'components.entities_list.submit' }] })}
      </Button>
    </Modal.Actions>)
  }

  render() {
    const {
      entityId,
      entityName,
      EntityForm
    } = this.props
    const entitiesName = pluralize(entityName)

    return (

       <Menu.Item onClick={() => { this.props.updateUI('open', true)}}>

         <Icon name='pencil'/>
        <Modal size='fullscreen' open={this.props.ui.open} onClose={() => this.props.updateUI('open', false)}>
          <Modal.Header>{I18n.t(`components.${entitiesName}_selector.new`)}</Modal.Header>
          <EntityForm entityId={entityId} FormWrapper={Modal.Content} SubmitButton={this.SubmitButton} onSubmitSuccess={this.handleEditSubmitSuccess}/>
        </Modal>
      </Menu.Item>)

  }

}

export default ui({state: { open: false}})(EditModal);
