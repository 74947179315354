import { fk, attr } from 'redux-orm'
import Base from './Base'

class EmailTemplate extends Base {
  static get fields() {
    return {
      id: attr(),
      subject: attr(),
      body: attr(),
      emailType: attr(),
      locationId: fk({to: 'location', as: 'location', relatedName: 'emailTemplates'}),
      officeId: fk({to: 'office', as: 'office', relatedName: 'emailTemplates'}),
    }
  }
}

EmailTemplate.modelName = 'emailTemplate'

export default EmailTemplate;
