import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'

class EmailTemplateForm extends React.Component {

  componentDidMount() {
  }

  render () {
    const uiSchema = {
      "attributes" : {
        "subject": {
          "ui:autofocus": true,
          "ui:emptyValue": "",
          "ui:placeholder": "",
          "ui:options": {
            width: 6
          }
        },
        "body": {
          "ui:widget": "DraftWidget",
          "ui:emptyValue": "",
          "ui:placeholder": "",
          "ui:options": {
            width: 8
          },
        }
      },
      "relationships" : {
        "location": {
          "data" : {
            "id": {
            "ui:widget" : "hidden"
            }
          }
        },
        "office": {
          "data" : {
            "id": {
            "ui:widget" : "hidden"
            }
          }
        }
      }
    }

    return (
      <SchemaForm entityName='emailTemplate' uiSchema={uiSchema} submitButton={this.props.submitButton} entityId={this.props.entityId}/>
      )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

EmailTemplateForm.defaultProps = {
  submitButton: true
}

export default connect(null, mapDispatchToProps)(EmailTemplateForm);
