import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, createEntity } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import ConsultationsListItem from 'components/consultations/ConsultationsListItem'
import ConsultationForm from 'components/consultations/ConsultationForm'

import { getSortedConsultationsForListByKeyWindow } from 'selectors/consultations'

const mapStateToProps = (state, ownProps) => {
  let consultations = getSortedConsultationsForListByKeyWindow(ownProps.keyWindow)(state)

  return {
    entities: consultations,
    entityName: 'consultation',
    item: ConsultationsListItem,
    loading: state.meta?.consultation?.loading,
    error: state.meta?.consultation?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExtraEntities: () => {
      dispatch(fetchEntities('dossiers'))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('locations'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
