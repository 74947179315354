import React from 'react'
import DateTimePicker from 'components/generics/DateTimePicker'

import { Form } from 'semantic-ui-react'

import 'semantic-ui-popup/popup.min.js'
import 'semantic-ui-transition/transition.min.js'

import { actions } from 'react-redux-form'
import { connect } from 'react-redux'

import moment from 'moment'

moment.locale('nl')

import { DateInput } from 'semantic-ui-calendar-react'

import dotProp from 'dot-prop'

class SemanticDateWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateString: props.date || ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if( prevProps.date != this.props.date) {
      this.setState({dateString: this.props.date || ''})
    }
  }

  parseDate = (value) => {
    if(value.length > 0) {
      const formats = [ "DDMMYYYY", "DD-MM-YYYY",  "DD/MM/YYYY", "DD-M-YYYY",  "DD/M/YYYY",  "D-MM-YYYY",  "D/MM/YYYY", "D-M-YYYY",  "D/M/YYYY"]
      for (var i = 0; i < formats.length; i++) {
        var format = formats[i]
        var parsedDate  = moment(value, format, true)
        if(parsedDate.isValid()) {
          return parsedDate
        }
      }
      return null
    } else {
      return null
    }
  }

  handleChange = (event, { value }) => {
    const { entityModel, date } = this.props
    this.setState({dateString: value});
    if (!value) {
      return this.props.dispatch(actions.change(entityModel, ''))
    }

    if (this.parseDate(value)) {
      return this.props.dispatch(actions.change(entityModel, this.parseDate(value).format("YYYY-MM-DD")))
    }
  }

  render() {
    const { entityModel, date } = this.props
    const { dateString } = this.state

    return (
      <DateInput dateFormat={'DD-MM-YYYY'} initialDate={date} value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
      </DateInput>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var rawDate = moment(dotProp.get(state,entityModel), "YYYY-MM-DD")
  if (rawDate.isValid()) {
    var date = rawDate.format("DD-MM-YYYY")
  }
  return {
    date: date,
    entityModel: entityModel
  }
}


export default connect(mapStateToProps)(SemanticDateWidget);
