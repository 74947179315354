import React from 'react'
import { connect } from 'react-redux'
import { Menu, Search, Input } from 'semantic-ui-react'
import debounce from 'debounce'
import { getSearchResults } from 'selectors/search_results'
import { withRouter } from 'react-router'
import { fetchSearchResults } from 'actions/search_results'

class SearchMenuItem extends React.Component {
  handleResultSelect = (e, { result }) => {
    if (result.url) {
      this.props.history.push(result.url)
    }
  }

  handleSearchChange = (e, { value }) => {
    this.props.search(value)
  }

  render() {
    const { results, search } = this.props
    return (
      <Menu.Item>
        <Search
          category
          onResultSelect={this.handleResultSelect}
          onSearchChange={debounce(this.handleSearchChange, 500,  true)}
          results={results}
          minCharacters={2}
          placeholder={I18n.t('components.search_menu_item.placeholder')}
          search={search}
        />
      </Menu.Item>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    results: getSearchResults(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (text) => {
      dispatch(fetchSearchResults(text))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchMenuItem));
