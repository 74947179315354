import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'
import isUuid from 'is_uuid'

import _ from 'lodash'

export const getDossiers = ormSelector(orm, getEntities, (session) => {
  var dossiers = session.dossier.all().toModelArray().map(dossier => {
    const { ref } = dossier
    return { ...ref,
      patient: session.contact.withId(dossier.patientId),
      responsible: session.employment.withId(dossier.responsibleId)
    }
  })

  return _.sortBy(dossiers, (dossier) => { return `${dossier.patient?.lastName} ${dossier.patient?.firstName}`  })
})

export const getDossiersFilter = state => state.meta.dossier.filter

export const getFilteredDossiers = createSelector([getDossiers, getDossiersFilter], (dossiers, filter) => {

  return dossiers.filter( (dossier) =>  { return (!isUuid(dossier.id)) && (!filter || (filter.length == 0) || (`${dossier.patient.firstName} ${dossier.patient.lastName}`.toLowerCase().indexOf(filter.toLowerCase()) > -1) || (`${dossier.patient.lastName} ${dossier.patient.firstName}`.toLowerCase().indexOf(filter.toLowerCase()) > -1)
  )})
})
