import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities, updateEntity, upsertEntity, createEntityWithDefaults } from 'actions/entities';
import { fetchCalendarsSync } from 'actions/calendars_sync';
import EventsCalendar from 'components/agenda/EventsCalendar';

import moment from 'moment'

import { getEvents } from 'selectors/events'

const mapStateToProps = (state, ownProps) => {
  var events = getEvents(state, ownProps)

  events = events.map( (event) => {
    event['startTime'] = new Date(event.startTime)
    event['endTime'] = new Date(event.endTime)
    return event
  })

  return {
    events: events,
    loading: state.meta.event.loading,
    error: state.meta.event.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEvents: (startTime, endTime) => {
      dispatch(fetchEntities('events', { name: 'eventsCalendar', filter: { startTime: startTime, endTime: endTime}, page: 1 }))
    },
    fetchConsultations: (startTime, endTime) => {
      dispatch(fetchEntities('consultations', { name: 'eventsCalendar', filter: { startTime: startTime, endTime: endTime}, page: 1 }))
    },
    fetchCalendarsSync: () => {
      dispatch(fetchCalendarsSync())
    },
    acceptEvent: ({event, start, end} ) => {
      let duration = null

      if (event.new) {
        const newConsultationId = dispatch(createEntityWithDefaults('consultation',{ consultationTypeId: event.consultationType.id, dossierId: event.dossier.id, practionerId: event.dossier.responsible.id  }))
        if (newConsultationId) {
          console.log(newConsultationId)
          const newEventId = dispatch(createEntityWithDefaults('event', { startTime: start, endTime: null, duration: "1:00",  consultationId: newConsultationId, calendarId: event.calendar.id} ))
          console.log(newEventId)
          if (newEventId) {
            dispatch(upsertEntity('event', newEventId, ['consultation']))
          }
        }
      } else {
        if (end) {
          var diff = moment(end).unix() - moment(start).unix();
          // execution
          duration = moment.utc(diff*1000).format("HH:mm");
        }

        dispatch(updateEntity('event', { id: event.id, startTime: start, endTime: end, duration: duration }))
        dispatch(upsertEntity('event',  event.id))
      }
    }


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsCalendar);
