import { getJson, postJson } from 'api_helpers.js'

export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS';
export function requestSearchResults() {
  return {
    type: REQUEST_SEARCH_RESULTS
  };
}

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export function receiveSearchResults(results) {
  return {
    type: RECEIVE_SEARCH_RESULTS,
    results: results
  };
}

export const FETCH_SEARCH_RESULTS_FAILURE = 'FETCH_SEARCH_RESULTS_FAILURE';
export function fetchSearchResultsFailure(error) {
  return {
    type: FETCH_SEARCH_RESULTS_FAILURE,
    error: error
  };
}


export const FETCH_SEARCH_RESULTS= 'FETCH_SEARCH_RESULTS';
export function fetchSearchResults(query) {
  return function(dispatch, getState) {

    var admin = getState().admin

    if (admin) {
      // TODO admin search
      var searchResultsPath = '' // Routes.admin_api_search_results_path()
    } else {
      var searchResultsPath = Routes.api_search_results_path({query: query})
    }

    dispatch(requestSearchResults())

    return getJson(searchResultsPath).then (
      response => response.json(),
      error => dispatch(fetchSearchResultsFailure(error))
    ).then (
      json => {
        dispatch(receiveSearchResults(json));
      }
    )
  }
}
