import { fk, attr } from 'redux-orm'
import Base from './Base'

class Notification extends Base {
  static get fields() {
    return {
      id: attr(),
      resolvedAt: attr(),
      resolutionType: attr(),
      titleKey: attr(),
      consultationReminderId :fk({to: 'consultationReminder', as: 'consultationReminder', relatedName: 'notification'}),
      generateConsultationErrorId :fk({to: 'generateConsultationError', as: 'generateConsultationError', relatedName: 'notification'}),
      removeConsultationErrorId :fk({to: 'removeConsultationError', as: 'removeConsultationError', relatedName: 'notification'}),
    }
  }
}

Notification.modelName = 'notification'

export default Notification;
