import React from 'react'
// Devtools
import {composeWithDevTools} from 'redux-devtools-extension'
// Redux stuff
import thunk from 'redux-thunk'
import { createRootReducer } from 'reducers/admin'
import {createStore, applyMiddleware, compose} from 'redux'
import history from 'custom_history'

export const configureStore = initialState => {
  const thunkApplied = applyMiddleware(thunk)
  let middlewares = null

  if (process.env.NODE_ENV === 'development') {
    middlewares = composeWithDevTools(thunkApplied)
  } else {
    middlewares = compose( thunkApplied)
  }

  var store = createStore(createRootReducer(history), initialState, middlewares)

  return { store };
}
