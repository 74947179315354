import React from 'react'
import { Segment, Header, Menu, Grid, Divider, } from 'semantic-ui-react'

import { connect } from 'react-redux'

import ContactForm from 'components/contacts/ContactForm'

import { findEntity } from 'selectors/entities'

class Contact extends React.Component {
  render() {
    const { contact } = this.props

    if (!contact) {
      return <div/>
    }

    return (
      <Segment className='min-height-90'>
          <Header dividing>
            <Grid columns={2}>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={14}>
                  <Header as='h3'>
                    {`${I18n.t('components.contact.title')}: ${contact.firstName} ${contact.lastName}`}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
         <ContactForm entityId={this.props.contactId}/>
       </Segment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contact: findEntity(state,'contact', ownProps.contactId)
  };
}

export default connect(mapStateToProps)(Contact);
