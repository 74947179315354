import addressSchema from 'forms/addressSchema'

const locationSchema = function(state, props) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "primaryColor": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      },
      "ui:widget": "color"
    },
    "logo": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "FileWidget"
    },
    "responsibleId": {
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "employment",
        "displayAttribute": (employment) => `${employment?.user?.firstName} ${employment?.user?.lastName}`,
      }
    },
    ...addressSchema(state,props)
  }

  return uiSchema
}

export default locationSchema;
