import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getLocations = ormSelector(orm, getEntities, (session) => {
  return session.location.all().toModelArray().map(location => {
    const { ref } = location
    return { ...ref}
  })
})

