import React from 'react'
import { Menu, Button, Item, Segment, Card, Icon } from 'semantic-ui-react'
import GenerateConsultationErrorForm from 'components/generate_consultation_errors/GenerateConsultationErrorForm'
import { connect } from 'react-redux'
import { upsertEntity, updateEntity } from 'actions/entities'
import ui from 'redux-ui'

import underscored from 'underscore.string/underscored'

class GenerateConsultationErrorsListItem extends React.Component {

  SubmitButton = () => {

    return (<Card.Content>
            <div className='ui buttons right floated'>
              <Button color='green' onClick={ (event) => { this.props.updateGenerateConsultationError('resolved', this.props.generateConsultationError.id)}}  >{I18n.t(`components.notifications_list_item.resolve`)}</Button> : null
              <Button color='red' onClick={ (event) => { this.props.updateGenerateConsultationError('ignored', this.props.generateConsultationError.id)}}>{I18n.t(`components.notifications_list_item.ignore`)}</Button>
            </div>
          </Card.Content>)
  }


  renderSuggestions() {
    if (this.props.matchType == 'Dossier') {
      return this.renderDossierSuggestions()
    } else if (this.props.matchType == 'ConsultationType') {
      return this.renderConsultationTypeSuggestions()
    }
  }

  renderDossierSuggestions() {
    if (this.props.tags && (this.props.tags.length < 10)) {
      return this.props.tags.map((tag) => {
        return tag.dossier && tag.dossier.patient && tag.dossier.patient.firstName ?  <Button key={tag.id} onClick={ () => this.props.updateGenerateConsultationError('resolved', this.props.generateConsultationError.id, tag.dossier.id)}>
          {`${tag.dossier.patient.firstName} ${tag.dossier.patient.lastName}`}
        </Button> : null
      })
    }
  }

  renderConsultationTypeSuggestions() {
    return this.props.tags.map((tag) => {
      return tag.consultationType && tag.consultationType.name ?  <Button key={tag.id} onClick={ () => this.props.updateGenerateConsultationError('resolved', this.props.generateConsultationError.id, tag.consultationType.id)}>
        {`${tag.consultationType.name}`}
      </Button> : null
    })
  }

  componentDidMount () {
    this.props.updateUI('isFormVisible', this.props.tags.length == 0);
  }

  render () {
    const error = this.props.generateConsultationError
    const matchType = underscored(error.matchType)

    return (
        <Card.Content>
          <Card.Meta>
            {error.event && error.event.startTime ? I18n.l("time.formats.long", error.event.startTime) : null }
          </Card.Meta>
          <Card.Description>
            {I18n.t(`components.generate_consultation_errors_list_item.error_descriptions.${matchType}.${this.props.errorType}`, { match: this.props.match, correction: this.props.correction })}
          </Card.Description>
          <Button.Group>
            {this.renderSuggestions(error)}
            { !this.props.ui.isFormVisible ?  <Button onClick={() => this.props.updateUI('isFormVisible', true)}>{I18n.t(`components.generate_consultation_errors_list_item.other`)}</Button> : null }
          </Button.Group>
          {this.props.ui.isFormVisible ? <GenerateConsultationErrorForm entityId={error.id} SubmitButton={this.SubmitButton}/> : null }
        </Card.Content>
      )
  }


}

var GenerateConsultationErrorsListItemWithUI = ui({ state: { isFormVisible: false} })(GenerateConsultationErrorsListItem);

const mapStateToProps = (state, ownProps) => {
  const error = ownProps.generateConsultationError
  const matchType = error.matchType

  var tags = []
  if (matchType == 'Dossier') {
    tags = (error.event && error.event.eventDossierTags) || []
  } else if (matchType == 'ConsultationType') {
    tags = (error.event && error.event.eventConsultationTypeTags) || []
  } else {
    // throw `Invalid matchType ${matchType}`
  }

  const match = (tags[0] || {}).match || ''
  const correction  = (tags[0] || {}).needle || ''

  return {
    error: error,
    tags: tags,
    matchType: matchType,
    errorType: error.errorType,
    correction: correction,
    match: match
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGenerateConsultationError: (resolutionType, generateConsultationErrorId, matchId = null) => {

      if(matchId) {
        dispatch(updateEntity('generateConsultationError', { id: generateConsultationErrorId, resolutionType: resolutionType, matchId: matchId }))
      } else {
        dispatch(updateEntity('generateConsultationError', { id: generateConsultationErrorId, resolutionType: resolutionType }))
      }


      dispatch(upsertEntity('generateConsultationError', generateConsultationErrorId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateConsultationErrorsListItemWithUI);
