import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { fetchEntities, upsertEntity, createEntity } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import HealthFundsListItem from 'components/admin/health_funds/HealthFundsListItem'
import HealthFundForm from 'components/admin/health_funds/HealthFundForm'

import { getHealthFunds } from 'selectors/health_funds'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getHealthFunds(state, ownProps),
    entityName: 'healthFund',
    item: HealthFundsListItem,
    form: HealthFundForm,
    listComponent: Card.Group,
    loading: state.meta.healthFund.loading,
    error: state.meta.healthFund.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('healthFunds'))
    },
    upsertEntity: (entityId) => {
      dispatch(upsertEntity('healthFund', entityId))
    },
    createEntity: () => {
      dispatch(createEntity('healthFund'))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
