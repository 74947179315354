import React from 'react'
import DateTimePicker from 'components/generics/DateTimePicker'

import { Form } from 'semantic-ui-react'

import 'semantic-ui-popup/popup.min.js'
import 'semantic-ui-transition/transition.min.js'

import { actions } from 'react-redux-form'
import { connect } from 'react-redux'

import moment from 'moment'

import dotProp from 'dot-prop'

class SemanticDateTimeWidget extends React.Component {
  render() {
    const { entityModel, dateTime } = this.props

    const formatter= { datetime: (datetime, settings) => { return moment(datetime).format('DD-MM-YYYY hh:mm') }
    }

    const parser = { date: (text, settings) => {
      if(text.length > 0) {
        const formats = [ "DD-MM-YYYY hh:mm", "DDMMYYYY hh:mm", "DD/MM/YYYY hh:mm", "hh:mm DD-MM-YYYY", "hh:mm DDMMYYYY", "hh:mm DD/MM/YYYY"]
        for (var i = 0; i < formats.length; i++) {
          var format = formats[i]
          var parsedDate  = moment(text, format, true)
          if(parsedDate.isValid()) {
            return parsedDate.toDate()
          }
        }
        return null
      } else {
        return ''
      }
    }}

    return (
      <DateTimePicker type='datetime' today={true} onChange={ (dateTime, text, mode) => { this.props.dispatch(actions.change(entityModel, dateTime)) }  } formatter={formatter}>
        <Form.Input defaultValue={dateTime} icon='calendar' onChange={(event) => { if (event.target.value.length == 0) { return this.props.dispatch(actions.change(entityModel, null)) }}}/>
      </DateTimePicker>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var dateTime = moment(dotProp.get(state,entityModel)).format("DD-MM-YYYY hh:mm")
  return {
    dateTime: dateTime,
    entityModel: entityModel
  }
}


export default connect(mapStateToProps)(SemanticDateTimeWidget);
