import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import LocationsListContainer from 'components/locations/LocationsListContainer';
import LocationsMenuContainer from 'components/locations/LocationsMenuContainer';
import LocationForm from 'components/locations/LocationForm';

export default class Locations extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.location_path({ id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <LocationsMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <LocationForm entityId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.locations_path()} component={LocationsListContainer}/>
        </Switch>
      </div>
    )
  }
}

