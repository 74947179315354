import React from 'react'
import { Statistic, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'
import { getConsultationsInDateRange } from 'selectors/consultations'
import moment from 'moment'

class Stats extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render() {
    const { numberOfConsultationsToday, numberOfConsultationsTomorrow,  numberOfConsultationsThisWeek, numberOfConsultationsNextWeek } = this.props
    return (
      <Grid padded={true} centered>
        <Grid.Row>
          <Statistic>
            <Statistic.Value>{numberOfConsultationsToday}</Statistic.Value>
            <Statistic.Label>{I18n.t('components.stats.consultations_today')}</Statistic.Label>
          </Statistic>
        </Grid.Row>
        <Grid.Row>
          <Statistic>
            <Statistic.Value>{numberOfConsultationsTomorrow}</Statistic.Value>
            <Statistic.Label>{I18n.t('components.stats.consultations_tomorrow')}</Statistic.Label>
          </Statistic>
        </Grid.Row>
        <Grid.Row>
          <Statistic>
            <Statistic.Value>{numberOfConsultationsThisWeek}</Statistic.Value>
            <Statistic.Label>{I18n.t('components.stats.consultations_this_week')}</Statistic.Label>
          </Statistic>
        </Grid.Row>
        <Grid.Row>
          <Statistic>
            <Statistic.Value>{numberOfConsultationsNextWeek}</Statistic.Value>
            <Statistic.Label>{I18n.t('components.stats.consultations_next_week')}</Statistic.Label>
          </Statistic>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    numberOfConsultationsToday: getConsultationsInDateRange(state, { startDate: moment().startOf('day'), endDate: moment().endOf('day') }).length,
    numberOfConsultationsTomorrow: getConsultationsInDateRange(state, { startDate: moment().add(1, 'days').startOf('week'), endDate: moment().add(1,'days').endOf('week') }).length,
    numberOfConsultationsThisWeek: getConsultationsInDateRange(state, { startDate: moment().startOf('week'), endDate: moment().endOf('week') }).length,
    numberOfConsultationsNextWeek: getConsultationsInDateRange(state, { startDate: moment().startOf('week').add(7,'days'), endDate: moment().endOf('week').add(7,'days') }).length,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultations', { name: 'stats', filter: {startTime: moment().startOf('week').toISOString(),  endTime: moment().endOf('week').toISOString() }, page: 1 }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)
