import pluralize from 'pluralize'
import underscored from 'underscore.string/underscored'
import modelReducer from '../form/model-reducer'
import { combineReducers } from 'redux'
import dotProp from 'dot-prop-immutable'
import invariant from 'invariant'
import _ from 'lodash'

export default function createMetaReducer(orm) {
  const session = orm.session(orm.getEmptyState())
  let reducers = {}
  session.sessionBoundModels.forEach(modelClass => {
    reducers[modelClass.modelName] = createEntitiesMetaReducer(modelClass.modelName)
  })
  return combineReducers(reducers)
}

function createEntitiesMetaReducer(entityName) {
  let entitiesName = pluralize(entityName)
  return combineReducers({
    keyWindows: createEntitiesKeyWindowsReducer(entityName),
    loading: createEntitiesLoadingReducer(entityName),
    errors: createEntitiesErrorsReducer(entityName),
    forms: createEntitiesFormsReducer(entityName),
    filter: createEntitiesFilterReducer(entityName),
    edited: createEntitiesEditedReducer(entityName)
  })
}

function createEntitiesKeyWindowsReducer(entityName) {
  const initialState = []
  let entitiesName = pluralize(entityName)
  return combineReducers({ allWindows: createEntitiesKeyWindowsAllWindowsReducer(entityName), byWindow: createEntitiesKeyWindowsByWindowReducer(entityName)})
}

function createEntitiesKeyWindowsAllWindowsReducer(entityName) {
  const initialState = []
  let entitiesName = pluralize(entityName)
  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `CLEAR_${underscored(entityName).toUpperCase()}`: {
        return []
      }
      case `BULK_CREATE_${underscored(entityName).toUpperCase()}`: {
        if (action.keyWindow && action.keyWindow.name) {
          return [...new Set(state.concat(action.keyWindow.name))]
        } else {
          return state
        }
      }
      case `BULK_UPSERT_${underscored(entityName).toUpperCase()}`: {
        if (action.keyWindow && action.keyWindow.name) {
          return _.uniq([...new Set(state.concat(action.keyWindow.name))])
        } else {
          return state
        }
      }
      default: {
        return state
      }
    }
  }
}

function createEntitiesKeyWindowsByWindowReducer(entityName) {
  const initialState = {}
  let entitiesName = pluralize(entityName)
  return function reducer(state = initialState, action) {
    if (action.type ===  `CLEAR_${underscored(entitiesName).toUpperCase()}`) {
      return {}
    }
    else if (action.type ===  `CLEAR_${underscored(entityName).toUpperCase()}`) {
      // TODO filter id from all key windows
      return state;
    }
    else if (action.type ===  `CLEAR_ALL_NEW`) {
      const newState = Object.keys(state).reduce( (result, keyWindow) => {
        result[keyWindow] = {...state[keyWindow],...{ allIds: state[keyWindow].allIds.filter( (id) => { return (id > 0) } ) } }
        return result
      }, {})
      return newState;
    }
    else if (action.type ===  `BULK_CREATE_${underscored(entityName).toUpperCase()}`) {
      if (action.keyWindow && action.keyWindow.name) {
        let keyWindowName = action.keyWindow.name

        let allIds = (state[keyWindowName] && state[keyWindowName].allIds) || []
        if (action.keyWindow.append) {
          return {...state, [keyWindowName]: {...state[keyWindowName], allIds: [...new Set(allIds.concat(Object.keys(action.payload)))], ...action.keyWindow}}
        } else {
          return {...state, [keyWindowName]: {...state[keyWindowName], allIds: Object.keys(action.payload), ...action.keyWindow}}
        }
      } else {
        return state
      }
    }
    else if (action.type ===  `BULK_UPSERT_${underscored(entityName).toUpperCase()}`) {
      if (action.keyWindow && action.keyWindow.name) {
        let keyWindowName = action.keyWindow.name

        let allIds = (state[keyWindowName] && state[keyWindowName].allIds) || []
        if (action.keyWindow.append) {
          return {...state, [keyWindowName]: {...state[keyWindowName], allIds: [...new Set(allIds.concat(Object.keys(action.payload)))], ...action.keyWindow}}
        } else {
          return {...state, [keyWindowName]: {...state[keyWindowName], allIds: Object.keys(action.payload), ...action.keyWindow}}
        }
      } else {
        return state
      }
    }
    else if (action.type ===  `NEW_${underscored(entityName).toUpperCase()}`) {
      // TODO where should new entities belong?
      return state
    }
    else {
      return state
    }
  }
}



function createEntitiesFilterReducer(entityName) {
  const initialState = null
  let entitiesName = pluralize(entityName)

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `FILTER_${underscored(entitiesName).toUpperCase()}`:
        return action.payload
      default:
        return state
    }
  }
}

function createEntitiesErrorsReducer(entityName) {
  const initialState = {}
  let entitiesName = pluralize(entityName)

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `RECEIVE_ERROR_${underscored(entityName).toUpperCase()}`:
        let appendedState = dotProp.set(state, `${action.payload.id}.${action.payload.model}`, action.payload.error)
        return appendedState
      case `CLEAR_ERRORS_${underscored(entityName).toUpperCase()}`:
        let id = action.payload
        let {[id]: deletedErrors, ...newState} = state
        return newState;
      case `CLEAR_ALL_ERRORS`:
        return {};
      default:
        return state
    }
  }
}

function createEntitiesFormsReducer(entityName) {
  const initialState = {}
  let entitiesName = pluralize(entityName)

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `RECEIVE_FORM_${underscored(entityName).toUpperCase()}`:
        let appendedState = dotProp.set(state, `${action.payload.id}`, action.payload.form)
        return appendedState
      case `CLEAR_FORM_${underscored(entityName).toUpperCase()}`:
        let id = action.payload
        let {[id]: deletedErrors, ...newState} = state
        return newState;
      case `CLEAR_ALL_FORMS`:
        return {};
      default:
        return state
    }
  }
}
function createEntitiesEditedReducer(entityName) {
  const initialState = null
  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `EDIT_${underscored(entityName).toUpperCase()}`:
        return action.payload
      default:
        return state
    }
  }
}

function createEntitiesLoadingReducer(entityName) {
  const initialState = false
  let entitiesName = pluralize(entityName)

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `REQUEST_${underscored(entitiesName).toUpperCase()}`:
        return true
      case `RECEIVE_${underscored(entitiesName).toUpperCase()}`:
        return false
      case `FETCH_${underscored(entitiesName).toUpperCase()}_FAILURE`:
        return false
      case `CREATE_${underscored(entityName).toUpperCase()}_FAILURE`:
        return false
      case `UPDATE_${underscored(entityName).toUpperCase()}_FAILURE`:
        return false
      default:
        return state

    }
  }
}
function createEntitiesPreloadedReducer(entityName) {
  const initialState = false
  let entitiesName = pluralize(entityName)

  return function reducer(state = initialState, action) {
    if (action.type ===  `CLEAR_${underscored(entitiesName).toUpperCase()}`) {
      return false
    }
    return state
  }
}
