import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'

import dossierItemBatchSchema from 'forms/dossierItemBatchSchema'

import { findEntity } from 'selectors/entities'

class DossierItemBatchForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }
  render () {
    const { keyWindow, dossier, uiSchema } = this.props

    if(dossier) {
      return (
        <SchemaForm batch='dossierItem' entityName='dossier' resourceName='dossierItemBatch' uiSchema={uiSchema} submitButton={true} entityId={dossier.id}/>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {

  const { dossierId } = ownProps;

  const dossier = dossierId && findEntity(state, 'dossier', dossierId)

  return {
    dossier: dossier,
    uiSchema: dossierItemBatchSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('dossiers'))
      dispatch(fetchEntities('dossierItems'))
    },
    handleSubmit: () => {
      dispatch(upsertEntity('dossierItem', ownProps.entityId, [], 'dossierItem')).then( () => {
        if(ownProps.onSubmitSuccess) {
          ownProps.onSubmitSuccess()
        }
      })
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DossierItemBatchForm);
