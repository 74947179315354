import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getContacts = ormSelector(orm, getEntities, (session) => {
  return session.contact.all().toModelArray().map(contact => {
    const { ref } = contact
    return { ...ref}
  })
})

