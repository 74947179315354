import React from 'react';

import {
  Grid, Icon,
} from 'semantic-ui-react';

class Timeline extends React.Component {
  render() {
    const {
      direction, icon, title, time, description, tags, labelColor, lineHeight = 4, lineColor = 'grey', color = 'grey', children, last, first
    } = this.props;

    const isMobile = window.innerWidth <= 768;
    const iconSize = isMobile ? 'small' : 'large';

    return (
      <div>
        <Grid>
          <Grid.Row className={`Timeline-node ${last ? 'Timeline-last' :''  } ${first ? 'Timeline-first' :''  }`}>
            <Grid.Column width={1}>
              <div className="Timeline-line" style={{ background: lineColor }} />
              <Icon name={icon} size={iconSize} color={color} inverted circular style={{ boxShadow: `0 0 0 0.1em ${lineColor} inset` }} className="Timeline-icon"/>

              { time ? <React.Fragment><div className='Timeline-date'>{I18n.l("date.formats.short_human_readable", time)}</div>
              <div className='Timeline-hour'>
                {I18n.l("time.formats.hour", time) }
            </div></React.Fragment> : null }
            </Grid.Column>
            <Grid.Column width={15}>
              {children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Timeline;
