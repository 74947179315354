import React from 'react'

import EditorNode from 'components/forms/editor/EditorNode';

export default class NoteNode extends React.Component {
  render() {
    // const { editor, node, children, isSelected, attributes, connectDragSource, connectDragPreview, isDragging , connectDropTarget, isOver } = this.props
    return <EditorNode icon={'sticky note'} type={'note'} {...this.props}/>
  }
}
