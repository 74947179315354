import React from 'react'
import { Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export default class DossiersListItem extends React.Component {

  render () {
    const dossier = this.props.dossier
    return (
      <Card as={NavLink} key={dossier.id} to={Routes.dossier_path({id: dossier.id, _options: true})} centered>
        <Card.Content>
          <Card.Header>
            {dossier.patient && dossier.patient.lastName} {dossier.patient && dossier.patient.firstName}
          </Card.Header>
          <Card.Meta>
            {I18n.t('components.dossier_list_item.since', { date: I18n.l("date.formats.default",dossier.createdAt) })}
          </Card.Meta>
          <Card.Meta>
            {dossier.patient && dossier.patient.email}
          </Card.Meta>
          <Card.Meta>
            {dossier.patient && dossier.patient.phone}
          </Card.Meta>
        </Card.Content>
    </Card>)
  }
}

