import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities,  createEntityWithDefaults, destroyEntity, refreshEntities, editEntity } from 'actions/entities';
import { fetchResources } from 'actions/resources';

import CalendarsList from 'components/configuration/CalendarsList';

import { getCalendars } from 'selectors/calendars';
import { getSelectableCalendars } from 'selectors/selectable_calendars';


const mapStateToProps = (state, ownProps) => {
  return {
    calendars: getCalendars(state, ownProps),
    selectableCalendars: getSelectableCalendars(state,ownProps),
    loading: state.meta.calendar.loading,
    error: state.meta.calendar.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCalendars: () => {
      dispatch(fetchResources())
      return dispatch(fetchEntities('calendars'))
    },
    newCalendar: () => {
      let newCalendarId = dispatch(createEntityWithDefaults('calendar'))
      dispatch(editEntity('calendar', newCalendarId))
    },
    destroyCalendar: (calendar) => {
      dispatch(destroyEntity('calendar', calendar.id))
      dispatch(refreshEntities('events'))
      dispatch(refreshEntities('consultations'))
      dispatch(refreshEntities('generateConsultationErrors'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarsList);
