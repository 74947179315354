import React, { Fragment } from 'react'
import {  Button, Icon, Dropdown } from 'semantic-ui-react'
import {  Modal } from 'semantic-ui-react-with-store'
import { createEntityWithDefaults , clearNewEntities } from 'actions/entities';
import pluralize from 'pluralize'
import underscored from 'underscore.string/underscored'
import ui from 'redux-ui'
import { connect } from 'react-redux'
import { ReactReduxContext } from 'react-redux'
import { setSetting } from 'actions/settings'
import { withRouter } from 'react-router'
import { entitiesRoute, entityRoute } from 'route_helpers'


class NewEntityModal extends React.Component {
  SubmitButton = (props) => {
    const saveOptions = [
      { key: 'overview', icon: 'list', text: I18n.t('components.entities_list.overview'), value: 'overview' },
      { key: 'edit', icon: 'edit', text: I18n.t('components.entities_list.continue'), value: 'edit' },
    ]

    return(<Modal.Actions>
      <Button color='red' inverted onClick={ (event, data) => {this.handleCancel()}} >
        <Icon name='close' /> {I18n.t('components.entities_list.cancel')}
      </Button>
      <Button.Group color='green'>
        <Button color='green' onClick={ (event, data) => {props.submit()}} >
          <Icon name='checkmark' /> {I18n.t('components.entities_list.submit')}
        </Button>

        <Dropdown color='green' options={saveOptions} button value={props.afterSaveNavigation} onChange={(event, { value }) =>  this.props.handleAfterSaveNavigationChange(value)} upward>
        </Dropdown>
      </Button.Group>
    </Modal.Actions>)
  }

  handleCancel= () => {
    this.props.clearNewEntities()
    this.props.updateUI('modalOpen', false)
  }

  handleSubmitSuccess = (entityId) => {
    this.props.updateUI('modalOpen', false)
    this.props.clearNewEntities()
    const entitiesName = pluralize(this.props.entityName)
    if (this.props.afterSaveNavigation == 'overview') {
      this.props.history.push(entitiesRoute(entitiesName))
    } else {
      this.props.history.push(entityRoute(this.props.entityName, false, { id: entityId }))
    }
  }


  render () {
    const EntityForm = this.props.form
    const Trigger = this.props.trigger

    let SubmitButton = null
    if (this.props.SubmitButton) {
      SubmitButton =  ((props) => <this.props.SubmitButton onCancel={this.handleCancel} {...props}/>)

    } else {
      SubmitButton = this.SubmitButton
    }

    const entitiesName = pluralize(this.props.entityName)

    return (<Fragment>
      <Trigger key='trigger' onClick={() => { let id = this.props.createEntityWithDefaults(); this.props.updateUI('modalOpen', true); this.props.updateUI('modalEntityId',id) }} />
      <Modal key='modal' size='fullscreen' open={this.props.ui.modalOpen} onClose={() => this.props.updateUI('modalOpen', false)} closeIcon>
        <Modal.Header>{I18n.t(`components.${underscored(entitiesName)}_list.new`)}</Modal.Header>
        { this.props.ui.modalEntityId &&
        <EntityForm entityId={this.props.ui.modalEntityId} FormWrapper={Modal.Content} SubmitButton={SubmitButton} onSubmitSuccess={this.handleSubmitSuccess}/> }
      </Modal>
    </Fragment>)
  }
}

const mapStateToProps = (state, ownProps) => {
  const entityName = ownProps.entityName
  return {
    afterSaveNavigation: state.settings[`${entityName}AfterSaveNavigation`] || 'overview'
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  const entityName = ownProps.entityName
  const entitiesName = pluralize(ownProps.entityName)
  return {
    handleAfterSaveNavigationChange: (value) => {
      return dispatch(setSetting(`${entityName}AfterSaveNavigation`, value))
    },
    clearNewEntities: () => {
      return dispatch(clearNewEntities())

    },
    createEntityWithDefaults: () => {
      if(ownProps.createEntityWithDefaults) {
        return ownProps.createEntityWithDefaults(ownProps)
      } else {
        return dispatch(createEntityWithDefaults(entityName))

      }
    }
  };
}

export default ui({state: { modalOpen: false, modalEntityId: null }})(connect(mapStateToProps, mapDispatchToProps)(withRouter(NewEntityModal)));
