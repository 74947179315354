import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { upsertEntity } from 'actions/entities'
import generateConsultationErrorSchema from 'forms/generateConsultationErrorSchema'

class GenerateConsultationErrorForm extends React.Component {
  render () {
    return (
      <SchemaForm entityName='generateConsultationError' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: generateConsultationErrorSchema(state, ownProps)
  }
}


export default connect(mapStateToProps)(GenerateConsultationErrorForm);
