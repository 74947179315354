import React from 'react'
import { connect } from 'react-redux'

import { Form, Button, Divider} from 'semantic-ui-react'

import dotProp from 'dot-prop'

import underscored from 'underscore.string/underscored'

import { actions as formActions } from "react-redux-form"

class SemanticButtonSelectWidget extends React.Component {
  render() {

    const { selectValue, entityModel, options } = this.props
    const selectOptions = options["selectOptions"]

    return (
      <Button.Group>
        {selectOptions.map( (selectOption, index) => {
          var active = (selectOption.value == selectValue)
          var buttons = []
          buttons.push(
              <Button key={selectOption.key} active={active} as='div' onClick={ (event, props) => { this.props.dispatch(formActions.change(entityModel, selectOption.value))}}>
                {selectOption.text}
              </Button>)

          if (index != (selectOptions.length - 1)) {
            buttons.push(<Button.Or text={I18n.t('generic.or')}/>)
          }

          return (buttons)
        })}
      </Button.Group>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`

  console.log('entityModel')
  console.log(entityModel)

  var selectValue = dotProp.get(state,entityModel) || ownProps.options.emptyValue
  return {
    selectValue: selectValue,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticButtonSelectWidget)
