import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'
import getEntities from 'selectors/entities'

export const getEmailTemplates = ormSelector(orm, getEntities, (session) => {
  return session.emailTemplate.all().toModelArray().map(emailTemplate => {
    const { ref } = emailTemplate
    return { ...ref}
  })
})
