import dotProp from 'dot-prop-immutable';
import { UPDATE_UI_STATE, MOUNT_UI_STATE } from 'actions/ui'

// For updating multiple UI variables at once.  Each variable might be part of
// a different context; this means that we need to either call updateUI on each
// key of the object to update or do transformations within one action in the
// reducer. The latter only triggers one store change event and is more
// performant.

export const defaultState = {}

export default function reducer(state = defaultState, action) {
  let key = action.payload && (action.payload.key || []);

  if (!Array.isArray(key)) {
    key = [key];
  }

  if (action.type == UPDATE_UI_STATE) {
    const { name, value } = action.payload;
    return dotProp.set(state,key.concat(name), value);
  } else if (action.type == MOUNT_UI_STATE) {
    const { value } = action.payload;
    return dotProp.set(state, key, value)
  }
  return state;
}


