import { fk, attr } from 'redux-orm'
import Base from './Base'

class DossierItem extends Base {
  static get fields() {
    return {
      id: attr(),
      text: attr(),
      path: attr(),
      dossierItemType: attr(),
      fileExpiringUrl: attr(),
      dossierId: fk('dossier', 'dossierItems'),
      consultationId: fk('consultation', 'dossierItems')
    }
  }
}

DossierItem.modelName = 'dossierItem'

export default DossierItem;
