import React from 'react'
import { Card, Menu, Icon, Label, Button } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import DestroyModal from 'components/generics/DestroyModal'

export default class ConsultationTypesListItem extends React.Component {
  handleDestroy = () => {
    this.props.destroyEntity(this.props.consultationType.id)
  }

  render () {

    const consultationType = this.props.consultationType
    return (
      <Card link key={consultationType.id}>
        <Card.Content as={NavLink} to={Routes.admin_consultation_type_path({id: consultationType.id, _options: true})}>
          <Card.Header>
            {consultationType.name}
          </Card.Header>
          { consultationType.createdAt ?
          <Card.Meta>
            {I18n.t('components.consultation_type_list_item.since', { date: I18n.l("date.formats.default",consultationType.createdAt) })}
          </Card.Meta> :
              null
          }
        </Card.Content>
        <Card.Content extra>
          <Icon name={consultationType.icon || 'question'} size='large'/>
          <DestroyModal trigger={<Button color='red' size='mini' compact floated='right' inverted>
            <Icon name='trash' /> {I18n.t('components.consultation_type_list_item.destroy')}
          </Button>} handleDestroy={this.handleDestroy}>
             {I18n.t('components.consultation_type_list_item.destroy_warning')}
          </DestroyModal>
        </Card.Content>
    </Card>)
  }
}

