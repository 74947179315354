import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'

import { getKeyWindowSelector } from 'selectors/key_windows'
import getEntities from 'selectors/entities'


const getNotifications = ormSelector(orm, getEntities, (session) => {
  return session.notification.all().toModelArray().map(notification => {
    const { ref } = notification
    return { ...ref,
      generateConsultationError: session.generateConsultationError.withId(notification.generateConsultationErrorId),
      removeConsultationError: session.removeConsultationError.withId(notification.removeConsultationErrorId),
      consultationReminder: session.consultationReminder.withId(notification.consultationReminder),

    }
  })
})

export const getUnresolvedNotifications = createSelector( [ getNotifications], (notifications) => {
  return notifications.filter( notification => {  return (!notification.resolvedAt )})
})
export const getMenuItemNotifications = getKeyWindowSelector('notification', 'menuItem', getUnresolvedNotifications)




