import React from 'react'
import { Sidebar, Item, Menu, Icon, Container, Grid, Segment} from 'semantic-ui-react'
import store from 'store'
import { Provider } from 'react-redux'
import Flash from 'components/generics/Flash'
import Offices from 'components/admin/offices/Offices'
import ConsultationTypes from 'components/admin/consultation_types/ConsultationTypes'
import HealthFunds from 'components/admin/health_funds/HealthFunds'
import { Switch, Route, NavLink, Router, matchPath } from 'react-router-dom'
import history from 'custom_history'
import airbrake from 'airbrake_client'

import ErrorPage from './generics/ErrorPage'

export default class AdminApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error, info) {

    this.setState({error: error})
    if(process.env.NODE_ENV == 'production') {
      airbrake.notify({ error: error, params: { state:  store.getState()}});
    }
  }

  render () {
    if (this.state.error) {
      return (<ErrorPage redirectUrl={Routes.admin_root_path()} error={this.state.error}/>)
    } else {
      return(<Provider store={store}>
        <Router history={history}>
          <div className="page-layout">
            <Menu fixed="top" color='teal' inverted>
              <Menu.Item>
                Zorgmee: Admin
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item as={ NavLink } to={Routes.destroy_user_session_path()} data-method="delete">
                  <Icon name="sign out"/>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <div className="main">
              <Flash/>
              <Switch>
                <Route path={Routes.admin_offices_path()} component={Offices}/>
                <Route path={Routes.admin_consultation_types_path()} component={ConsultationTypes}/>
                <Route path={Routes.admin_health_funds_path()} component={HealthFunds}/>
              </Switch>
            </div>
            <Sidebar as={Menu} visible={true} direction="bottom" animation="push" inverted={true} widths={5} icon='labeled' size="massive" color='teal'>
              <Menu.Item as={ NavLink } to={Routes.admin_offices_path()}>
                <Icon name='building outline'/>
                {I18n.t('admin.components.admin_app.menu.offices')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.admin_users_path()}>
                <Icon name='users'/>
                {I18n.t('admin.components.admin_app.menu.users')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.admin_consultation_types_path()}>
                <Icon name='clock'/>
                {I18n.t('admin.components.admin_app.menu.consultation_types')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.admin_health_funds_path()}>
                <Icon name='hospital'/>
                {I18n.t('admin.components.admin_app.menu.health_funds')}
              </Menu.Item>
            </Sidebar>
          </div>
        </Router>
      </Provider>
      )
    }
  }
}

