import React from 'react'

import { Tab, Menu } from 'semantic-ui-react'
import { Route, NavLink } from 'react-router-dom'
import { matchPath } from 'react-router'

class RoutedTab extends React.Component {
  render() {
    const { panes } = this.props

    let tabPanes = panes.map(pane => {
      return {
        menuItem: <Menu.Item key={pane.key} as={NavLink} to={pane.path}>{pane.label}</Menu.Item>,
        render: () => {
          return <Tab.Pane basic>{pane.render()}</Tab.Pane>
        }
      }
    })

    let defaultActiveIndex = 0

    panes.map((pane,index) => {
      if (matchPath(window.location.pathname, { path: pane.path, exact: true})) {
        defaultActiveIndex = index
      }
    })

    return <Tab menu={{secondary: true, pointing: true}} panes={tabPanes} className='min-height-90' defaultActiveIndex={defaultActiveIndex}/>
  }
}

export default RoutedTab;
