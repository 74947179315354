import ContactForm from 'components/contacts/ContactForm'
import OrganisationForm from 'components/organisations/OrganisationForm'

import { getPractioners } from 'selectors/employments'

const dossierSliceSchema = function(state, props, filter, invoiceReceiver) {
  let forDossier = (filter == 'dossier')
  let forFinancial = (filter == 'financial')
  let forCommunication = (filter == 'communication')

  let uiSchema = {
    "startTime": {
      "ui:widget": "hidden",
    },
    "endTime": {
      "ui:widget": "hidden",
    },
  }

  if (forDossier) {
    uiSchema = {...uiSchema,...{
      "healthFundId": {
        "ui:field": "SelectorField",
        "ui:options": {
          "entityName": "healthFund",
          "displayAttribute": (healthFund) => `${healthFund.name}`,
        }
      },
      "responsibleId": {
        "ui:field": "SelectorField",
        "ui:options": {
          "entityName": "employment",
          "displayAttribute": (employment) => `${employment.user?.firstName} ${employment.user?.lastName}`,
          "selector": getPractioners
        }
      },
      "locationId": {
        "ui:field": "SelectorField",
        "ui:options": {
          "entityName": "location",
          "displayAttribute": (location) => `${location.name}`,
        }
      },
    }}
  } else {
    uiSchema = {...uiSchema,...{
      "healthFundId": {
        "ui:widget": "hidden"
      },
      "responsibleId": {
        "ui:widget": "hidden"
      },
      "locationId": {
        "ui:widget": "hidden"
      },
    }}
  }

  if (forFinancial) {
    uiSchema = {...uiSchema,
      "invoicedOrganisationId": {
      "ui:visible": invoiceReceiver == 'organisation',
      "ui:field": "SelectorField",
      "ui:options": {
        width: 4,
        "entityName": "organisation",
        "addable": true,
        "editable": true,
        "includeBlank": true,
        "EntityForm": OrganisationForm,
        "displayAttribute": (organisation) => `${organisation.name}`,
      },
    },
    "invoicedContactId": {
      "ui:visible": invoiceReceiver == 'contact',
      "ui:field": "SelectorField",
      "ui:options": {
        width: 4,
        "entityName": "contact",
        "addable": true,
        "editable": true,
        "includeBlank": true,
        "EntityForm": ContactForm,
        "displayAttribute": (contact) => `${contact.firstName} ${contact.lastName}`,
      },
    },
    "invoiceReceiver": {
      "ui:widget": "ButtonSelectWidget",
      "ui:options": {
        selectOptions: [
          {key: 'patient', value: 'patient', text: I18n.t('components.dossier_form.invoice_receiver.patient') },
          {key: 'contact', value: 'contact', text: I18n.t('components.dossier_form.invoice_receiver.contact') },
          {key: 'organisation', value: 'organisation', text: I18n.t('components.dossier_form.invoice_receiver.organisation') },
        ]
      }
    }
    }
  } else {
    uiSchema = {...uiSchema,...{
      "invoicedOrganisationId": {
        "ui:widget": "hidden",
      },
      "invoicedContactId": {
        "ui:widget": "hidden",
      },
      "invoiceReceiver": {
        "ui:widget": "hidden",
      }
    }}
  }

  if (forCommunication) {

    uiSchema = {...uiSchema,...{
      "communicationContactId": {
        "ui:group": "communication",
        "ui:field": "SelectorField",
        "ui:options": {
          width: 4,
          "entityName": "contact",
          "addable": true,
          "editable": true,
          "includeBlank": true,
          "displayAttribute": (contact) => `${contact.firstName} ${contact.lastName}`,
        },
      },
      "communicationOrganisationId": {
        "ui:group": "communication",
        "ui:field": "SelectorField",
        "ui:options": {
          width: 4,
          "entityName": "organisation",
          "addable": true,
          "includeBlank": true,
          "editable": true,
          "displayAttribute": (organisation) => `${organisation.name}`,
        },
      },
      "communicationReceiver": {
        "ui:widget": "ButtonSelectWidget",
        "ui:group": "invoiced",
        "ui:options": {
          selectOptions: [
            {key: 'patient', value: 'patient', text: I18n.t('components.dossier_form.invoice_receiver.patient') },
            {key: 'contact', value: 'contact', text: I18n.t('components.dossier_form.invoice_receiver.contact') },
            {key: 'organisation', value: 'organisation', text: I18n.t('components.dossier_form.invoice_receiver.organisation') },
          ]
        }
      }
    }
    }
  } else {
    uiSchema = {...uiSchema,...{
      "communicationContactId": {
        "ui:widget": "hidden",
      },
      "communicationOrganisationId": {
        "ui:widget": "hidden",
      },
      "communicationReceiver": {
        "ui:widget": "hidden",
      }
    }
    }
  }

  return uiSchema
}

export default dossierSliceSchema;
