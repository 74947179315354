/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
// import "./routes_whitelist";
//
import "stylesheets/app-styles";
import "stylesheets/semantic_ui_less/compiled";
import "stylesheets/semantic-ui-overrides";
import "babel-polyfill";
//
import "cable";
import browserUpdate from 'browser-update';

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

document.addEventListener("DOMContentLoaded", function(event) {
  browserUpdate({
      l: I18n.locale,
      style: 'corner',
      required:{
          e: -2,
          f: -2,
          o: -2,
          o_a: -2,
          s: -1,
          c: -2,
          y: -2,
          v: -2 ,
          uc: -2,
          samsung: -2
      }
  });
});
