import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'
import ContactForm from 'components/contacts/ContactForm'
import { getContacts } from 'selectors/contacts'

import {  createEntityWithDefaults } from 'actions/entities';

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getContacts(state),
    entityName: 'contact',
    item: (props) => <div>{`${props.contact.lastName} ${props.contact.firstName}`}</div>,
    form: ContactForm,
    loading: state.meta?.contact?.loading,
    error: state.meta?.contact?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('contactSlices'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('contacts', filter))
    },
    createEntityWithDefaults: () => {
      let contactId = dispatch(createEntityWithDefaults('contact'))
      let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
      return contactId
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
