import React from 'react'
import { connect } from 'react-redux'
import { List } from 'semantic-ui-react'

import { fetchEntities } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import EmploymentsListItem from 'components/employments/EmploymentsListItem'


import { getEmployments } from 'selectors/employments'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getEmployments(state, ownProps),
    entityName: 'employment',
    item: EmploymentsListItem,
    listComponent: List,
    loading: state.meta.employment.loading,
    error: state.meta.employment.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExtraEntities: () => {
      dispatch(fetchEntities('employments'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
