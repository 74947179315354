import React from 'react'

import { DropTarget } from 'react-dnd';

import { Header, Segment } from 'semantic-ui-react';

class EditorNodeDrop extends React.Component {

  render() {
    const { isOver, connectDropTarget, structural } = this.props
    return connectDropTarget(<div>
      <Segment raised={isOver} placeholder>
        <Header>
          { structural ?
            I18n.t(`components.editor_node_drop.help_text_insert_after`) : I18n.t(`components.editor_node_drop.help_text_insert_child`) }

        </Header>
    </Segment></div>)
  }
}

export default DropTarget('editor-node', {
  hover: (props,monitor, component) => {
  },
  drop: (props,monitor, component) => {
    return { key: props.node.key, action: props.structural ? "insertChild" : "insertAfter" }
  }
}
,
(connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}
) (EditorNodeDrop)
