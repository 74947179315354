
import { fk, attr } from 'redux-orm'
import Base from './Base'

class Office extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      iban: attr(),
      phoneNumber: attr(),
      slug: attr(),
      invoiceConditions: attr(),
      invoiceMessage: attr(),
      enterpriseNumber: attr(),
      startInvoiceIdentification: attr(),
      fixedStartInvoiceIdentification: attr(),
      current: attr()
    }
  }
}

Office.modelName = 'office'

export default Office;

