import React, { Component } from "react";
import { Segment } from 'semantic-ui-react';

class EditorCore extends React.Component {
  render() {
    const { content } = this.props
    return (
      <Segment basic className="editor-core no-padding">
        { content }
      </Segment>
    )
  }
}

// export default DropTarget('editor-node',
//   { hover: (props, monitor, component) => {
// } },
// (connect, monitor) => {
//   connectDropTarget: connect.dropTarget();
// }
// )(EditorCore)

export default EditorCore
