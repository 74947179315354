import ActionCable from 'actioncable'

class AppChannels {
  constructor() {
    this.cable = ActionCable.createConsumer();
  }
}

const instance = new AppChannels();

export default instance;
