import React from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

class ChannelsInfo extends React.Component {
  componentDidMount() {
  }

  render() {
    const { channels } = this.props
    return (<Icon.Group as='div'>
        {Object.keys(channels).map ( (id) => {
          let channel = channels[id]
            return (<Icon size='tiny' key={id} name='circle' color={channel.connection_state == 'connected' ? 'green' : 'red' }/>)
        })}
      </Icon.Group>)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    progresses: state.progress.byId,
    channels: state.channels.byId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsInfo);
