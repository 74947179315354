import React from 'react'

import { Menu, Divider } from 'semantic-ui-react';

import { Block } from 'slate'

export default class AddBar extends React.Component {

  insertNote = () => {
    const { editor, attachedNode } = this.props
   // editor.command('insertBlock', { type: 'note' })
    editor.command('insertNodeByKey', attachedNode.key, 0,  Block.create({ type: 'note' }) )
  }

  render() {
    const { removeNode, vertical } = this.props

    return (
        <Divider vertical={vertical} contentEditable={false}>
          <Menu secondary borderless>
            <Menu.Item icon='sticky note' onClick={() => this.insertNote()}>
            </Menu.Item>
            { removeNode &&
            <Menu.Menu position='right'>
              <Menu.Item icon='trash' onClick={removeNode}>
              </Menu.Item>
            </Menu.Menu> }
          </Menu>
        </Divider>)
  }
}
