import createHistory from 'history/createBrowserHistory';
import {  matchPath } from 'react-router-dom'
import { fetchEntities } from 'actions/entities';

const history = createHistory();

const initialMatch = matchPath(window.location.pathname, { path: '/:officeSlug/*' })

var currentOfficeSlug = ''
if (initialMatch) {
  currentOfficeSlug = initialMatch.params.officeSlug;
}

if (currentOfficeSlug && currentOfficeSlug != 'admin') {
  Routes.configure({
    default_url_options: { office_slug: currentOfficeSlug}
  })
}

// history.listen((location, action) => {
//   const match = matchPath(location.pathname, { path: '/:officeSlug#<{(|' })
//   Routes.configure({
//     default_url_options: { office_slug: match.params.officeSlug }
//   })
// })
//

export default history;
