import { fk, attr } from 'redux-orm'
import Base from './Base'

class Dossier extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      invoiceReceiver: attr(),
      locationId: fk('location'),
      patientId: fk({to: 'contact', as: 'patient', relatedName: 'dossier'}),
      invoicedContactId: fk( { to: 'contact', as: 'invoicedContact', relatedName: 'invoicedDossiers'}),
      communicationContactId: fk({ to:'contact', as: 'communicationContact', relatedName:  'communicationDossiers' }),
      invoicedOrganisationId: fk({ to: 'organisation', as: 'invoicedOrganisation', relatedName:  'invoicedDossiers'}),
      communicationOrganisationId: fk({ to: 'organisation', as: 'communicationOrganisation', relatedName: 'communicationDossiers'}),
      healthFundId :fk({ to: 'healthFund', as: 'healthFund', relatedName: 'dossiers' })
    }
  }
}

Dossier.modelName = 'dossier'

export default Dossier;
