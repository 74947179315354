export * from './Address'
export * from './Calendar'
export * from './Contact'
export * from './ContactSlice'
export * from './Consultation'
export * from './ConsultationType'
export * from './ConsultationPrice'
export * from './Country'
export * from './Dossier'
export * from './DossierItem'
export * from './DossierSlice'
export * from './EmailCommunication'
export * from './Employment'
export * from './Event'
export * from './HealthFund'
export * from './Invoice'
export * from './InvoiceItem'
export * from './Location'
export * from './Office'
export * from './Organisation'
export * from './User'
export * from './SelectableCalendar'
export * from './ConsultationReminder'
export * from './EmailTemplate'
export * from './EventConsultationTypeTag'
export * from './EventDossierTag'
export * from './GenerateConsultationError'
export * from './Notification'
export * from './Receipt'
export * from './RemoveConsultationError'

import Address from './Address'
import Calendar from './Calendar'
import Consultation from './Consultation'
import ConsultationType from './ConsultationType'
import ConsultationPrice from './ConsultationPrice'
import Contact from './Contact'
import ContactSlice from './ContactSlice'
import Country from './Country'
import Dossier from './Dossier'
import DossierItem from './DossierItem'
import DossierSlice from './DossierSlice'
import EmailCommunication from './EmailCommunication'
import Event from './Event'
import Employment from './Employment'
import HealthFund from './HealthFund'
import Invoice from './Invoice'
import InvoiceItem from './InvoiceItem'
import Location from './Location'
import Office from './Office'
import Organisation from './Organisation'
import User from './User'
import SelectableCalendar from './SelectableCalendar'
import ConsultationReminder from './ConsultationReminder'
import EmailTemplate from './EmailTemplate'
import EventConsultationTypeTag from './EventConsultationTypeTag'
import EventDossierTag from './EventDossierTag'
import GenerateConsultationError from './GenerateConsultationError'
import Notification from './Notification'
import Receipt from './Receipt'
import RemoveConsultationError from './RemoveConsultationError'


import { ORM } from 'redux-orm'

const orm = new ORM()
orm.register(Address, Calendar, Contact, ContactSlice,  ConsultationType, ConsultationPrice, Country, Location, Dossier, DossierSlice, DossierItem, HealthFund, EmailCommunication, Employment, Office, Organisation, User, Invoice, InvoiceItem, SelectableCalendar, Consultation, Event, ConsultationReminder ,EmailTemplate ,EventConsultationTypeTag ,EventDossierTag ,GenerateConsultationError ,Notification ,Receipt ,RemoveConsultationError)

export default orm;
