import React from 'react'
import { Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export default class HealthFundsListItem extends React.Component {

  render () {
    const healthFund = this.props.healthFund
    return (
      <Card as={NavLink} key={healthFund.id} to={Routes.admin_health_fund_path({id: healthFund.id, _options: true})}>
        <Card.Content>
          <Card.Header>
            {healthFund.name}
          </Card.Header>
          {/* <Card.Meta> */}
          {/*   {I18n.t('components.health_fund_list_item.since', { date: I18n.l("date.formats.default",health_fund.createdAt) })} */}
          {/* </Card.Meta> */}
        </Card.Content>
    </Card>)
  }
}

