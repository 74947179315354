import React from 'react'
import { Segment, List, Item, Button, Icon, Divider, Card } from 'semantic-ui-react'
import CalendarForm from 'components/calendars/CalendarForm'
import DestroyModal from 'components/generics/DestroyModal'

export default class CalendarsList extends React.Component {
  constructor(props) {
    super(props);
    this.destroyHandlers = {}
  }

  componentDidMount () {
    this.props.fetchCalendars().then( () => this.props.newCalendar());
  }

  handleDestroy = (calendar) => {
    return () => {
      this.props.destroyCalendar(calendar)
    }
  }

  renderCalendars(calendars) {
    return calendars.map((calendar) => {
      this.destroyHandlers[calendar.id] = this.handleDestroy(calendar)
      return (
        <Card key={calendar.id} style={{boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${calendar.color},0 1px 3px 0 #d4d4d5`}} >
          <Card.Content>
            <Card.Header>
              {calendar.summary}
            </Card.Header>
            <Card.Meta>{I18n.t(`components.calendar_list.calendar_type.${calendar.calendarType}`) }</Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <Icon name='calendar' size='large' style={{color: calendar.color}}/>
            <DestroyModal trigger={<Button color='red' size='mini' compact floated='right' inverted>
              <Icon name='trash' /> {I18n.t('components.calendar_list.destroy')}
            </Button>} handleDestroy={this.destroyHandlers[calendar.id]}>
               {I18n.t('components.calendar_list.destroy_warning')}
            </DestroyModal>
          </Card.Content>
        </Card>
      );
    });
  }

  render () {
    const { loading, error, calendars } = this.props

    if(loading) {
      return <div className="container"><h1>Calendars</h1><h3>Loading...</h3></div>
    } else if(error) {
      return <div className="alert alert-danger">Error: {error.message}</div>
    }

    return (
        <div>
          <Segment>
            <h1>{I18n.t('components.calendar_list.title')}</h1>
            <Card.Group>
              {this.renderCalendars(calendars)}
            </Card.Group>
            <Divider/>
            <CalendarForm/>
          </Segment>
        </div>
    )
  }
}
