import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import OfficesListContainer from 'components/admin/offices/OfficesListContainer';
import OfficesMenuContainer from 'components/admin/offices/OfficesMenuContainer';
import OfficeForm from 'components/offices/OfficeForm';

export default class Offices extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.admin_office_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <OfficesMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                {"Hier komt nog zorgverlener aanpassen"}
                {/* <OfficeForm entityId={props.match.params.id}/> */}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.admin_offices_path()} component={OfficesListContainer}/>
        </Switch>
      </div>
    )
  }
}

