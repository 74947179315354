import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'

import { fetchEntities } from 'actions/entities'

import dossierSchema from 'forms/dossierSchema'

import dotProp from 'dot-prop'

class DossierForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render() {
    return (
      <SchemaForm entityName='dossier' {...this.props}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: dossierSchema(state, ownProps),
    invoiceReceiver: dotProp.get(state, `entities.dossier.byId.${ownProps.entityId}.invoiceReceiver`)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('locations'))
      dispatch(fetchEntities('countries'))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('organisations'))
      dispatch(fetchEntities('healthFunds'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DossierForm);
