import React from 'react'

import { Form, Icon, Input } from 'semantic-ui-react'
import { actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'

import GeoSuggest from 'react-geosuggest'

class SemanticGeocoderWidget extends React.Component {
  handleChange = (value) => {
    const { entityModel } = this.props
    this.props.dispatch(formActions.change(entityModel, value))
  }

  handleSuggestSelect = (suggest) => {

    if (suggest) {
      let gmaps = suggest.gmaps
      var addressAttributes = {
        street_number: { name: 'short_name', attribute: 'houseNumber' },
        route: { name: 'long_name', attribute: 'street' } ,
        locality:  { name:  'long_name', attribute: 'city' },
        country: { name: 'short_name', attribute: 'country' },
        postal_code: { name: 'short_name' , attribute: 'postalCode' }
      };

      if (gmaps) {
        for (var i = 0; i < gmaps.address_components.length; i++) {
          var addressType = gmaps.address_components[i].types[0];
          let valuesForComponent = addressAttributes[addressType]
          if (valuesForComponent) {
            var value = gmaps.address_components[i][valuesForComponent['name']];
            if (addressType == "country" ) {
              value = value.toLowerCase()
            }
            this.props.dispatch(formActions.change(`${this.props.model}.${valuesForComponent['attribute']}`, value))
          }
        }
      }
    }
  }



  render() {
    const { value, entityModel } = this.props

    let location = (google && new google.maps.LatLng(51.0543422,3.717424299999948)) // Gent
    return (
      <GeoSuggest onChange={this.handleChange} value={value} location={location} radius={1000000} country={['be','nl','fr','uk','de']} placeholder={I18n.t('components.geocoder_widget.search')} inputClassName='ui input search' onSuggestSelect={this.handleSuggestSelect} autoActivateFirstSuggest={true} suggestsClassName={'dropdown menu'} suggestItemClassName={'item'} suggestItemActiveClassName={'selected'}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var value = dotProp.get(state,entityModel)
  return {
    value: value,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticGeocoderWidget);
