import { createSelector } from 'reselect'


import { getKeyWindowSelector } from 'selectors/key_windows'

import DossierItemsSerializer from 'slate-dossier-items-serializer'

import { createSelectorCreator, defaultMemoize } from 'reselect'

import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

import _ from 'lodash'

const createDeepEqualSelector = createSelectorCreator( defaultMemoize,
  _.isEqual
)

const getDossierId = (state, props) => props.dossierId

const getDossier = (state,props) => {
  if( getDossierId(state,props)) {
    return orm.session(getEntities(state,props)).dossier.withId(getDossierId(state,props))
  } else {
    return null
  }
}

const getConsultationsForDossier = createSelector([getDossier], (dossier) => {
  console.log("getting consultations")

  if (!dossier) {
    return []
  }


  return dossier.consultations.toRefArray()
})

export const getDossierItemsForDossier = createSelector([getDossier], (dossier) => {
  console.log("getting dossier items")
  if (!dossier) {
    return []
  }

  console.log("we should have some")

  return dossier.dossierItems.toModelArray().map(item => {
    const { ref } = item
    return {...ref,
      consultation: item.consultation
    }
  })
})

const getDossierSlicesForDossier = createSelector([getDossier], (dossier) => {
  console.log("getting dossier slices")
   if (!dossier) {
     return []
   }

  return dossier.dossierSlices.toRefArray()
})

export const getDossierItemsJson = createDeepEqualSelector([getDossier,getDossierSlicesForDossier, getDossierItemsForDossier, getConsultationsForDossier], (dossier, slices, items, consultations) => {

  console.log("getting dossier items")

   if (!dossier) {
     return "{}"
   }

    let allItems = []
    let indexForConsultationId = {}
    let indexForSliceId = {}

    allItems.push({ id: `dossier_${dossier.id}`, path: `0`, dossierItemType: 'dossier', dossierId: dossier.id })

    consultations.forEach( (consultation, index) => {
      indexForConsultationId[consultation.id] = index + 1
      allItems.push({ id: `consultation_${consultation.id}`, path: `${index + 1}`, dossierItemType: 'consultation', consultationId: consultation.id, dossierId: dossier.id })
    })

    slices.forEach( (slice, index) => {
      indexForSliceId[slice.id] = Object.keys(consultations).length + index + 1
      allItems.push({ id: `slice_${slice.id}`, path: `${indexForSliceId[slice.id]}`, dossierItemType: 'slice', sliceId: slice.id, dossierId: dossier.id })
    })

    items.forEach( (item) => {
      let topLevel = 0

      let consultation = item.consultation
      if (consultation) {
        topLevel = indexForConsultationId[consultation.id]
      }

      let newPath = `${topLevel}.${item.path}`
      allItems.push({...item, path: newPath, id: item.id, consultationId: consultation && consultation.id , dossierId: dossier.id})
    })

    // Add placeholder if no subNodes

    if(!allItems.some( (item) => { return (item.path == '0.0')} )) {
      allItems.push({dossierItemType: 'placeholder', path: '0.0', dossierId: dossier.id, id: `placeholder_${dossier.id}` })
      allItems.push({dossierItemType: 'text', path: '0.0.0', dossierId: dossier.id, id: `placeholder_text_${dossier.id}`, text: 'text' })
    }

    Object.keys(indexForConsultationId).forEach( (consultationId) => {
      let topLevelIndex = indexForConsultationId[consultationId]
      if(!allItems.some( (item) => { return (item.path == `${topLevelIndex}.0`)} )) {
        allItems.push({dossierItemType: 'placeholder', path: `${topLevelIndex}.0`, dossierId: dossier.id, consultationId: consultationId, id: `placeholder_consultation_${consultationId}` })
        allItems.push({dossierItemType: 'text', path: `${topLevelIndex}.0.0`, dossierId: dossier.id, consultationId: consultationId, id: `placeholder_text_consultation_${consultationId}`, text: 'text' })
      }
    })

    Object.keys(indexForSliceId).forEach( (sliceId) => {
      let topLevelIndex = indexForSliceId[sliceId]
      if(!allItems.some( (item) => { return (item.path == `${topLevelIndex}.0`)} )) {
        allItems.push({dossierItemType: 'placeholder', path: `${topLevelIndex}.0`, dossierId: dossier.id, sliceId: sliceId, id: `placeholder_slice_${sliceId}` })
        allItems.push({dossierItemType: 'text', path: `${topLevelIndex}.0.0`, dossierId: dossier.id, sliceId: sliceId, id: `placeholder_text_slice_${sliceId}`, text: 'text' })
      }
    })

    allItems = _.sortBy(allItems, ['path'])

    console.log("allItems from state")
    console.log(allItems)
    //
    let serializer = (new DossierItemsSerializer)

    let json = serializer.deserialize(allItems)
    return json
})
