import contactSliceSchema from 'forms/contactSliceSchema'

const contactSchema = function(state, props) {
  return {
    "ui:readonly": props.readonly,
    "firstName": {
      "ui:group": 'name',
      "ui:options": {
        width: 6
      }
    },
    "lastName": {
      "ui:group": 'name',
      "ui:options": {
        width: 6
      }
    },
    "email": {
      "ui:group": 'email',
      "ui:options": {
        width: 6
      }
    },
    "birthday": {
      "ui:group": 'email',
      "ui:options": {
        width: 2
      }
    },
    "contactSlices": {
      "ui:field": "SliceField",
      "ui:options": {
        "entityName": "contactSlice",
        "copiedRelationships": ["address"],
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "contactSlice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": contactSliceSchema(state,props)
      }
    }
  }
}

export default contactSchema;

