import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getEmployments = ormSelector(orm, getEntities, (session) => {
  return session.employment.all().toModelArray().map(employment => {
    const { ref } = employment
    return { ...ref,
      user: session.user.withId(employment.userId)
    }
  })
})

export const getCurrentEmployment = createSelector([getEmployments],
  (employments) => {
    return employments.find( employment =>  { return employment.current})
  }
)

export const getPractioners = createSelector([getEmployments], (employments) => {
    return employments.filter( employment => { return (employment.employmentType == 'practioner') })
})
