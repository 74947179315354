import React from 'react'
// Devtools
import {composeWithDevTools} from 'redux-devtools-extension'
// Redux stuff
import thunk from 'redux-thunk'
import { createRootReducer } from 'reducers'
import {createStore, applyMiddleware, compose} from 'redux'
import {persistStore} from 'redux-persist'
import airbrakeMiddleware from 'redux-airbrake'
import airbrake from 'airbrake_client'

import logic from 'logic'
import { createLogicMiddleware } from 'redux-logic'
import history from 'custom_history'

const indexResource = (resourceName, attributes) => {
  return ({ resources , indexDocument, state }) => {
    Object.keys(resources).forEach(id => {
      let resource = resources[id]
      attributes.forEach( (attribute) => {
        if (resource.attributes[attribute]) {
          indexDocument(id, resource.attributes[attribute])
        }
      })
    })
  }
}


export const configureStore = initialState => {

  let appliedMiddlewares = null

  let middlewares = null

  const logicMiddleware = createLogicMiddleware(logic);

  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
    appliedMiddlewares = applyMiddleware(thunk, logicMiddleware)
    middlewares = composeWithDevTools(appliedMiddlewares)
  } else {
    const errorMiddleware = airbrakeMiddleware(airbrake, {
      noticeAnnotations: { context: { environment: window.ENV }, sendLastAction: true, sendState: true }
    });
    appliedMiddlewares = applyMiddleware(thunk, logicMiddleware, errorMiddleware)
    middlewares = compose( appliedMiddlewares)
  }

  var store = createStore(createRootReducer(history), initialState, middlewares)

  let persistor = null
  if (process.env.NODE_ENV != 'test') {
    persistor = persistStore(store);
  }

  return { store, persistor }
}
