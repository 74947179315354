import { fk, attr } from 'redux-orm'
import Base from './Base'

class ContactSlice extends Base {
  copy(newId) {
    const address = this.address
    const newAddress = address.copy()
    const { ref } = this
    const newContactSlice = _.merge({}, ref, { addressId: newAddress.id, id: newId})
    console.log('newContactSlice')
    console.log(newContactSlice)
    return this.getClass().create(newContactSlice)
  }

  static get fields() {
    return {
      id: attr(),
      gender: attr(),
      phone: attr(),
      startTime: attr(),
      endTime: attr(),
      createdAt: attr(),
      addressId :fk({to: 'address', as: 'address', relatedName: 'contactSlices'}),
      contactId: fk({to: 'contact', as: 'contact', relatedName: 'contactSlices'})
    }
  }
}

ContactSlice.modelName = 'contactSlice'

export default ContactSlice;
