import React from 'react'

import { connect } from 'react-redux'

import { Segment, Header, Item, Button} from 'semantic-ui-react';

import { findEntity } from 'selectors/entities';

import DossierForm from 'components/dossiers/DossierForm';

import EditorNode from 'components/forms/editor/EditorNode';

export class DossierNode extends React.Component {
  constructor(props) {
    super(props)
    this.state = { collapsed: true }
  }

  renderExpanded() {
    const { editor, children, isSelected, node, patient, dossier, attributes } = this.props
    return (
      <React.Fragment>
        { patient && <Item.Header as={Header}>
          { patient.firstName } { patient.lastName }
          <Button basic icon='angle left' floated='right' onClick={ () => this.setState({ collapsed: true })}/>
        </Item.Header> }
        <Item.Content>
          <DossierForm entityId={dossier.id} readonly={true}/>
        </Item.Content>
      </React.Fragment>
    )
  }

  renderCollapsed() {
    const { editor, children, isSelected, node, patient, dossier, attributes } = this.props
    return (
      <React.Fragment>
        { patient && <Item.Header as={Header}>
          { patient.firstName } { patient.lastName }
          <Button basic icon='angle down' floated='right' onClick={() => this.setState({ collapsed: false })}/>
        </Item.Header> }
        <Item.Content>
          { patient.firstName}
        </Item.Content>
      </React.Fragment>
    )
  }

  render() {
    const { editor, children, isSelected, node, patient, dossier, attributes } = this.props
    const { collapsed } = this.state
    return (<div {...attributes}>
      <EditorNode structural={true} {...this.props} icon='user circle outline'>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false}>
          { collapsed && this.renderCollapsed() }
          { !collapsed && this.renderExpanded() }
        </Item>
        {children}
      </EditorNode>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossier = findEntity(state, 'dossier', ownProps.dossierId)
  let patient = findEntity(state, 'contact', dossier && dossier.patientId)

  return {
    dossier: dossier,
    patient: patient
  }
}

export default connect(mapStateToProps)(DossierNode)

