import { REQUEST_SEARCH_RESULTS, RECEIVE_SEARCH_RESULTS, FETCH_SEARCH_RESULTS_FAILURE } from 'actions/search_results'

const initialState = {
  results: {},
  loading: false,
  error: null,
}

export default function search_results(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEARCH_RESULTS:// start fetching search_results and set loading = true
      return { ...state,  ...{results: {}, error: null, loading: true}};
    case RECEIVE_SEARCH_RESULTS:// return list of search_results and make loading = false
      return { ...state, ...{results: action.results, error:null, loading: false}};
    case FETCH_SEARCH_RESULTS_FAILURE:// return error and make loading = false
      var error = "request failed"  // action.error || {message: action.error.message};//2nd one is network or server down errors
      return { ...state,  ...{results: {}, error: error, loading: false}};
  }
  return state
}
