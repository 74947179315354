import React from 'react'
import { connect } from 'react-redux'

import pluralize from 'pluralize'
import camelize from 'underscore.string/camelize'
import underscored from 'underscore.string/underscored'

import { actions } from "react-redux-form"
import { Form, Select, Button,  Input, Menu, Icon} from 'semantic-ui-react'

import EditModal from 'components/forms/EditModal'
import AddModal from 'components/forms/AddModal'

import { createEntityWithDefaults } from 'actions/entities';

import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'
import dotProp from 'dot-prop'

import { getUiSchema, getUiOptions, getSchema, getEntityNameFromOptions } from 'selectors/forms'
import { getPersistedEntities } from 'selectors/entities'

import {
  retrieveSchema,
  getDefaultRegistry,
} from "react-jsonschema-form/lib/utils";

class SemanticSelectorField extends ObjectField {
  render() {
    const {
      uiSchema,
      entityName,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      onBlur,
      onFocus,
      model,
      entityId,
      EntityForm,
      registry = getDefaultRegistry(),
    } = this.props;
    const { definitions, fields, formContext } = registry;
    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions);
    const title = schema.title === undefined ? name : schema.title;

    const entitiesName = pluralize(entityName)

    var entityModel = `${this.props.model || ''}.${this.props.name}`


    const { "ui:options": options , "ui:placeholder": placeholder}   = uiSchema

    const { width, addable, editable } = (options || {})

    const selectedOption = this.props.selectOptions.find((option) => { option.key == this.props.entityId } )

    return (
      <Form.Field width={width ||  2 }>
        { this.props.name == "id" ? <label htmlFor={this.props.name}>{I18n.t(underscored(this.props.translationScope))}{required ? "*" : null}</label>
        : <label htmlFor={this.props.name}>{I18n.t(`${this.props.translationScope}.${underscored(this.props.name)}`)}{required ? "*" : null}</label> }
        { readonly ?
            selectedOption && selectedOption.text || I18n.t('generic.unknown')

              : <React.Fragment>
        <Select search options={this.props.selectOptions} onChange={this.props.handleChange} value={this.props.entityId}/>
        <Menu compact icon borderless secondary>
          { addable && EntityForm ? <AddModal entityName={entityName} EntityForm={EntityForm} handleAddSubmitSuccess={ (id) => { this.props.handleChange( entityModel, id) }}/>  : null }
          { (editable && entityId && EntityForm) ? <EditModal entityId={entityId} entityName={entityName} EntityForm={EntityForm}/> : null }
      </Menu></React.Fragment> }

      </Form.Field>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  var entityModel = `${ownProps.model || ''}.${ownProps.name}`
  var entityName = ownProps.uiSchema["ui:options"].entityName
  var onChange = ownProps.uiSchema["ui:options"].onChange

  return {
    handleChange: (event, props) => { dispatch(actions.change(entityModel, props.value))
    },
    createEntityWithDefaults: () => {
      dispatch(createEntityWithDefaults(entityName))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityName = getEntityNameFromOptions(state, ownProps)
  var entitiesName = pluralize(entityName)
  var options = getUiOptions(state,ownProps)

  const { displayAttribute, valueAttribute, includeBlank, EntityForm, selector } = options

  var entityModel = `${ownProps.model || ''}.${ownProps.name}`
  var entityId = dotProp.get(state, entityModel)


  if (selector) {
    var entities = selector.call(null, state)
  } else {
    var entities = getPersistedEntities(entityName)(state, ownProps)
  }

  var selectOptions = entities.map( (entity) => {
    var text =''
    if(typeof displayAttribute === "function") {
      text = displayAttribute.call(null, entity)
    } else {
      text = entity[displayAttribute]

    }

    var value;
    if(typeof valueAttribute === "function") {
      value = valueAttribute.call(null, entity)
    } else {
      value = entity[valueAttribute || "id"]

    }

    return  { key: entity.id, value: value, text: text}
  })

  if (includeBlank) {
    selectOptions.unshift({key: '', value: '', text: I18n.t('components.semantic_select_field.blank')})
  }


  return {
    entityId: entityId,
    entityName: entityName,
    entityModel: entityModel,
    selectOptions: selectOptions,
    EntityForm: EntityForm,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticSelectorField);
