import { fk, attr, oneToOne } from 'redux-orm'
import Base from './Base'

class Event extends Base {
  static get fields() {
    return {
      id: attr(),
      startTime: attr(),
      endTime: attr(),
      duration: attr(),
      summary: attr(),
      description: attr(),
      displayColor: attr(),
      markedForRemoval: attr(),
      ignored: attr(),
      calendarId: fk({to: 'calendar', as: 'calendar', relatedName: 'events'}),
      consultationId: fk({to: 'consultation', as: 'consultation', relatedName: 'event'}),
    }
  }
}

Event.modelName = 'event'

export default Event;
