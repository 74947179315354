import addressSchema from 'forms/addressSchema'

const officeSchema = function(state, props) {
  return {
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "invoiceConditions": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "DraftWidget"
    },
    "invoiceMessage": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "DraftWidget"
    },
    "startInvoiceIdentification": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "UpDownWidget",
      "ui:options": {
        width: 2
      },
      "ui:readonly": props.fixedStartInvoiceIdentification
    },
    ...addressSchema
  }
}

export default officeSchema;
