import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import { Item, Label, Icon, Grid, Segment, Header, Statistic } from 'semantic-ui-react';

import { findEntity } from 'selectors/entities';

import EditorNode from 'components/forms/editor/EditorNode';

export class ConsultationNode extends React.Component {

  render() {
    const { consultation, event, patient, dossier, consultationType, attributes, children, isSelected } = this.props

    return (<div {...attributes}>
      <EditorNode structural={true} {...this.props} time={event && event.startTime} icon='calendar outline'>
        <Item  as={Segment} raised={isSelected} clearing contentEditable={false}>
          <Item.Content>
            <Label corner='left'>
              <Icon name={(consultationType || {}).icon || 'question'} size='big'/>
            </Label>
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={13}>
                  {/* <NavLink to={Routes.consultation_path({ id: consultation.id, _options: true})}> */}
                  { patient ?  <Header>{patient.firstName} {patient.lastName}</Header> : <Header/>}
                  <Item.Description>{(consultationType || {}).name || I18n.t('components.consultations_list.unknown_consultation_type')}</Item.Description>
                  {/* </NavLink> */}
                </Grid.Column>
                <Grid.Column width={2}>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Item.Content>
        </Item>
        {children}
      </EditorNode>
      </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  let consultation = findEntity(state, 'consultation', ownProps.consultationId)
  let event = findEntity(state, 'event', consultation && consultation.eventId)
  let consultationType = findEntity(state, 'consultationType', consultation && consultation.consultationTypeId)
  let dossier = findEntity(state, 'dossier', consultation && consultation.dossierId)
  let patient = findEntity(state, 'contact', dossier && dossier.patientId)

  return {
    consultation: consultation,
    event: event,
    patient: patient,
    dossier: dossier
  }
}

export default connect(mapStateToProps)(ConsultationNode);
