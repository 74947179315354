import React from 'react'
import { connect } from 'react-redux'
import RoutedTab from 'components/generics/RoutedTab'
import DossierBasicInfo from 'components/dossiers/DossierBasicInfo'
import DossierFinancialInfo from 'components/dossiers/DossierFinancialInfo'
import DossierFollowUp from 'components/dossiers/DossierFollowUp'
import { findEntity } from 'selectors/entities'

import { withRouter } from 'react-router-dom'

class Dossier extends React.Component {
  render() {
    const { dossier } = this.props

    if (!dossier) {
      return <div>Nog geen dossier ingeladen</div>
    }

    const panes = [
      { label: I18n.t('components.dossier.basic_info'), render: () => <DossierBasicInfo dossier={dossier}/> , path: Routes.dossier_path({id: dossier.id, _options: true })},

      { label: I18n.t('components.dossier.follow_up') , render: () => <DossierFollowUp dossierId={dossier.id}/>, path: Routes.dossier_feed_path({id: dossier.id, _options: true })},

      { label: I18n.t('components.dossier.financial') , render: () => <DossierFinancialInfo dossier={dossier}/>, path: Routes.dossier_financial_path({id: dossier.id, _options: true }) }
    ]

    return (
      <RoutedTab panes={panes}/>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    dossier: findEntity(state, 'dossier', ownProps.dossierId)
  }
}

export default withRouter(connect(mapStateToProps)(Dossier));
