const dossierItemBatchSchema = function(state, props) {

  const uiSchema = {
    "ui:readonly": props.readonly,
    "dossierItems": {
      "ui:field": "DossierEditor",
      "ui:options": {
        "entityName": "dossierItem",
        "displayTitle": false,
        "hideHeader": false,
      },
      "items" : {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "dossierItem",
          "displayTitle": false,
        },
        "ui:schema": {
          "ui:options": {
            "ui:width": 8
          },
          "position": {
            "ui:widget": "hidden"
          },
          "text": {
            "ui:emptyValue": "",
            "ui:placeholder": "",
            "ui:widget": "DraftWidget",
            "ui:options": {
              "label": false
            },
          },
          "dossierItemType": {
            "ui:widget": "hidden"
          },
        }
      },
    }
  }

  return uiSchema
}

export default dossierItemBatchSchema;
