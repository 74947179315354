import { Node, Value, KeyUtils } from 'slate'

let n = 0;

KeyUtils.setGenerator(() => {
  return `${n--}`
})

class DossierItemsSerializer {
  constructor(options = {}) {
    if (options.deserializeItem) {
      this.deserializeItem = options.deserializeItem
    } else {
      this.deserializeItem = this.defaultDeserializeItem
    }

    this.filters = options.filters
  }

  deserialize = (items) => {
    const json = {
      object: 'value',
      document: {
        object: 'document',
        data: {},
        nodes: this.deserializeToInnerJson(items)
      }
    }

    return json
  }

  deserializeToInnerJson = (items) => {
    let output = []

    for(let i = 0; i < items.length;  i++) {
      let item = items[i]
      let chain = item.path.split(".")
      let currentOutput = output
      for (var j = 0; j < chain.length; j++) {
        let currentKey = chain[j]
        if (currentOutput[currentKey]) {
          if (j == (chain.length - 1)) {
            currentOutput[currentKey] = {...this.deserializeItem(item), nodes: currentOutput[currentKey].nodes};
          }
          currentOutput = currentOutput[currentKey].nodes;
        } else {
          let newOutput
          if (j == (chain.length - 1)) {
            newOutput = currentOutput[currentKey] = {...this.deserializeItem(item), nodes: []}
          }
          else {
            newOutput = currentOutput[currentKey] = { nodes: []}
          }
          currentOutput = newOutput.nodes
        }
      }
    }

    return output
  }

  defaultDeserializeItem = (item) => {
    let data = { consultationId: item.consultationId, dossierId: item.dossierId, sliceId: item.sliceId }
    if (item.dossierItemType == 'text') {
      return {
        key: `${item.id}`,
        object: 'text',
        leaves: [
          {
            text: item.text,
          }
        ],
        data: data
      }
    } else if (item.dossierItemType == 'placeholder_text') {
      return {
        key: `${item.id}`,
        object: 'text',
        type: 'placeholder_text',
        leaves: [
          {
            text: item.text,
          }
        ],
        data: data
      }
    } else if (item.dossierItemType == 'note') {
      return {
        key: `${item.id}`,
        object: 'block',
        type: 'note',
        data: data
      }
    } else if (item.dossierItemType == 'attachment') {
      return {
        key: `${item.id}`,
        object: 'block',
        type: 'attachment',
        data: data
      }
    } else if (item.dossierItemType == 'consultation') {
      return {
        key: `${item.id}`,
        object: 'block',
        type: 'consultation',
        data: data
      }
    } else if (item.dossierItemType == 'dossier') {
      return {
        key: `${item.id}`,
        object: 'block',
        type: 'dossier',
        data: data
      }
    } else {
      return {
        key: `${item.id}`,
        object: 'block',
        type: item.dossierItemType,
        data: data
      }
    }
  }

  serialize = (value, options = {}) => {
    const { document } = value
    this.keysToPathsTable = document.getKeysToPathsTable()
    const items = document.nodes.toArray().reduce((result, node) => {
      return this.serializeNode(result, node, null)
    }, {})
    return items
  }

  serializeLeave = (node, result, leaf, parent) => {
    let path = this.keysToPathsTable[node.key].join(".")

    let relationships = {}

    if (node.data && node.data.toJS().dossierId) {
      relationships.dossierId =  node.data.toJS().dossierId
    } else if (parent && parent.data && parent.data.toJS().dossierId) {
      relationships.dossierId = parent.data.toJS().dossierId
    }

    if (node.data && node.data.toJS().consultationId) {
       relationships.consultationId = node.data.toJS().consultationId
    } else if (parent && parent.data && parent.data.toJS().consultationId) {
       relationships.consultationId = parent.data.toJS().consultationId
    }

    result[node.key] = {...{ dossierItemType: 'text', text: leaf.text, path: path }, ...relationships}
    return result
  }

  serializeNode = (result, node, parent) => {
    let path = this.keysToPathsTable[node.key].join(".")

    if(node.object == 'block' && !(this.filters.indexOf(node.type) > -1)) {
      return result
    }

    if (node.object === 'text') {
      const leaves = node.getLeaves()
      return leaves.toJS().reduce( (result,leaf) => { return this.serializeLeave(node, result, leaf, parent) }, result)
    } else {

      let relationships = {}


      if (node.data.toJS().dossierId) {
        relationships.dossierId = node.data.toJS().dossierId
      } else if (parent && parent.data.toJS().dossierId) {
        relationships.dossierId = parent.data.toJS().dossierId
      }

      if (node.data.toJS().consultationId) {
         relationships.consultationId = node.data.toJS().consultationId
      } else if (parent && parent.data.toJS().consultationId) {
         relationships.consultationId = parent.data.toJS().consultationId
      }

      let resultWithChildren = node.nodes.toArray().reduce((result, childNode) => {  return this.serializeNode(result, childNode, node) } , result)

      resultWithChildren[node.key] = { ...{dossierItemType: node.type, path: path}, ...relationships }

      return resultWithChildren
    }
  }
}

export default DossierItemsSerializer
