import React from 'react'
import underscored from 'underscore.string/underscored'
import { Item, Menu, Sidebar, Input, Button, Icon, Segment } from 'semantic-ui-react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { entityRoute, entitiesRoute } from 'route_helpers'

import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import NewEntityModal from 'components/entities/NewEntityModal';
import InfiniteScroll from 'components/generics/InfiniteScroll';

export default class EntitiesMenu extends React.Component {
  componentDidMount () {
    this.props.fetchEntities();
  }

  renderEntities(entities) {
    if (!this.props.entityName || !(this.props.entityName.length > 0) || !entities.length) {
      return (<Menu.Item> {I18n.t('components.entities_menu.not_found')}</Menu.Item>)
    } else {
      var EntityMenuItem = this.props.item
      var entitiesName = pluralize(this.props.entityName)
      return (entities.map((entity) => {
        var entityProps = {}
        entityProps[this.props.entityName] = entity
        return (<Menu.Item as={'a'} key={entity.id} href={entityRoute(this.props.entityName, this.props.admin, {id: entity.id})}>

          <EntityMenuItem {...entityProps} />
        </Menu.Item>)
      }))
    }
  }

  renderList() {
    const { entities, onLoadMore, activePage, totalPages } = this.props

    if (onLoadMore) {
      return (<InfiniteScroll onBottomVisible={ () => onLoadMore(activePage, totalPages) }>
        <Menu.Menu>{this.renderEntities(entities)}</Menu.Menu>
      </InfiniteScroll>)
    } else {
      return (<Menu.Menu>{this.renderEntities(entities)}</Menu.Menu>)
    }
  }

  render() {
    var entitiesName = pluralize(this.props.entityName)
    return (
        <Sidebar as={Menu} vertical visible={true}>
          <Menu.Item>
            <Menu.Header as={Link} to={entitiesRoute(pluralize(this.props.entityName), this.props.admin)}>
              { I18n.t(`components.${underscored(entitiesName)}_list.title`)}

            </Menu.Header>
            { this.props.form ?
            <NewEntityModal entityName={this.props.entityName} createEntityWithDefaults={this.props.createEntityWithDefaults} form={this.props.form} trigger={ (props) => {
              return (<Button basic floated='right' icon='plus' onClick={props.onClick}/>)} }
            /> : null }
          </Menu.Item>
          {/* { this.props.filterEntities ? */}
          {/*   <Menu.Item> */}
          {/*     <Menu.Menu> */}
          {/*       <Menu.Item> <Input icon='search' placeholder={I18n.t('components.entities_list.search')} onChange={(event) => this.props.filterEntities(event.target.value)}/> </Menu.Item> */}
          {/*     </Menu.Menu> */}
          {/*   </Menu.Item> */}
          {/*   : null */}
          {/* } */}
          <Menu.Item>
            { this.renderList() }
          </Menu.Item>
        </Sidebar>
      )
  }
}


