import React from 'react'
import { connect } from 'react-redux'

import { Menu, Icon } from 'semantic-ui-react'
import { sendEmail } from 'actions/send_email'

import { actions as formActions } from 'react-redux-form'

import { upsertEntity } from 'actions/entities'

class MarkPayedItem extends  React.Component {

  render() {
    const { invoice } = this.props

    return  (<Menu.Item onClick={() => {this.props.togglePayedAt(invoice) } }>
      <Icon.Group size='large'>
        <Icon name='euro'/>
        { invoice.payedAt ?  <Icon corner name='check circle' color='green'/> : null }
      </Icon.Group>
    </Menu.Item>)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    togglePayedAt: (invoice) => {
      if (invoice.payedAt) {
        dispatch(formActions.change(`entities.invoices.byId.${invoice.id}.attributes.payedAt`, null))
      } else {
        dispatch(formActions.change(`entities.invoices.byId.${invoice.id}.attributes.payedAt`, new Date()))
      }

      dispatch(upsertEntity('invoice', invoice.id))
    },
  };
}

export default connect(null, mapDispatchToProps)(MarkPayedItem);
