import { fk, attr } from 'redux-orm'
import Base from './Base'

class User extends Base {
  static get fields() {
    return {
      id: attr(),
      firstName: attr(),
      lastName: attr(),
      email: attr()
    }
  }
}

User.modelName = 'user'

export default User;
