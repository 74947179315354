import React from 'react'
import { Menu, Button, Item, Segment, Card, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { createEntityWithRelationships, upsertEntity, updateEntity } from 'actions/entities'

import underscored from 'underscore.string/underscored'

class ConsultationRemindersListItem extends React.Component {

  render () {
    const event = this.props.consultationReminder.consultation && this.props.consultationReminder.consultation.event

    return (
        <Card.Content>
          <Card.Meta>
            {event && event.startTime ? I18n.l("time.formats.long", event.startTime) : null }
          </Card.Meta>
          <Card.Description>
            {I18n.t(`components.consultation_reminder_list_item.description`)}
          </Card.Description>
          <Card.Content>
            <div className='ui buttons right floated'>
              <Button color='green' onClick={() => this.props.sendReminderEmail()}>{I18n.t(`components.consultation_reminder_list_item.send`)}</Button>
              <Button color='red' onClick={ () => this.props.ignoreReminder()}>{I18n.t(`components.notifications_list_item.ignore`)}</Button>
            </div>
          </Card.Content>
        </Card.Content>
      )
  }


}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let consultationReminder = ownProps.consultationReminder
  return {
    sendReminderEmail: () => {
      dispatch(createEntityWithRelationships('emailCommunication', 'emailCommunication', { emailCommunicationType: 'consultation_reminder'}, { consultationReminder: { id: consultationReminder.id, type: 'consultationReminders'}, dossier: {  id: consultationReminder.id, type: 'dossiers' }} ))

      dispatch(updateEntity('consultationReminder', { id: consultationReminder.id, resolutionType: 'resolved' }))
      dispatch(upsertEntity('consultationReminder', consultationReminder.id, ["emailCommunication"]))

    },
    ignoreReminder: () => {
      dispatch(updateEntity('consultationReminder', { id: consultationReminder.id, resolutionType: 'ignored' }))
      dispatch(upsertEntity('consultationReminder', consultationReminder.id, ["emailCommunication"]))
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ConsultationRemindersListItem);
