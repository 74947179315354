import React from 'react'
import DropzoneComponent from 'react-dropzone-component'
import { connect } from 'react-redux'
import { Image, Grid } from 'semantic-ui-react'

import { receiveEntity } from 'actions/entities'


import dotProp from 'dot-prop'

class SemanticFileWidget extends React.Component {
  render() {
    const componentConfig = {
        iconFiletypes: ['.jpg', '.png'],
        showFiletypeIcon: true,
        postUrl: this.props.route
    };

    const djsConfig = {
      addRemoveLinks: true,
      paramName: this.props.paramName,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
      },
      maxFilesize: 1,
      dictDefaultMessage: I18n.t("components.semantic_file_widget.dict_default_message"),
      dictFallbackMessage: I18n.t("components.semantic_file_widget.dict_fallback_message"),
      dictFallbackText: I18n.t("components.semantic_file_widget.dict_fallback_text"),
      dictFileTooBig: I18n.t("components.semantic_file_widget.dict_file_too_big"),
      dictInvalidFileType: I18n.t("components.semantic_file_widget.dict_invalid_file_type"),
      dictResponseError: I18n.t("components.semantic_file_widget.dict_response_error"),
      dictCancelUpload: I18n.t("components.semantic_file_widget.dict_cancel_upload"),
      dictCancelUploadConfirmation: I18n.t("components.semantic_file_widget.dict_cancel_upload_confirmation"),
      dictRemoveFile: I18n.t("components.semantic_file_widget.dict_remove_file"),
      dictMaxFilesExceeded: I18n.t("components.semantic_file_widget.dict_max_files_exceeded"),
      addRemoveLinks: false

    }

    const eventHandlers = {
      success: (file, response) => { this.props.dispatch(receiveEntity(this.props.entityName,response.data)  ) }
    }

    if(this.props.entityId > 0) {
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Image src={this.props.expiringUrl} size="medium"/>
              </Grid.Column>
              <Grid.Column width={12}>
                <DropzoneComponent config={componentConfig} djsConfig={djsConfig} eventHandlers={eventHandlers}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    } else {
      return <p>{I18n.t('components.semantic_file_widget.new_record')}</p>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  if (!Routes[`${ownProps.id}_api_${ownProps.formContext.entityName || ''}_path`]) {
    throw `Route not found: ${ownProps.id}_api_${ownProps.formContext.entityName || ''}_path`
  }

  var entityModel = `${ownProps.model || ''}.${ownProps.id}ExpiringUrl`
  var expiringUrl = dotProp.get(state,entityModel) || "/images/missing.png"
  const route = Routes[`${ownProps.id}_api_${ownProps.formContext.entityName || ''}_path`]({id: ownProps.formContext.entityId, _options: true})


  return {
    route: route,
    paramName: ownProps.id,
    entityId: ownProps.formContext.entityId,
    entityName: ownProps.formContext.entityName,
    expiringUrl: expiringUrl
  }
}

export default connect(mapStateToProps)(SemanticFileWidget);
