import React from 'react'
import { Menu, Button, Item, Segment, Card, Icon } from 'semantic-ui-react'
import GenerateConsultationErrorForm from 'components/generate_consultation_errors/GenerateConsultationErrorForm'
import { connect } from 'react-redux'
import { updateEntity, upsertEntity } from 'actions/entities'
import ui from 'redux-ui'

import underscored from 'underscore.string/underscored'

import RemoveConsultationErrorsListItem from 'components/remove_consultation_errors/RemoveConsultationErrorsListItem'
import GenerateConsultationErrorsListItem from 'components/generate_consultation_errors/GenerateConsultationErrorsListItem'
import ConsultationReminderListItem from 'components/consultation_reminders/ConsultationReminderListItem'


class NotificationsListItem extends React.Component {
  SubmitButton = (props) => {
    return (<Card.Content>
            <div className='ui buttons right floated'>
              <Button color='green' onClick={ () => this.props.updateNotification('resolved', this.props.notification.id)}>{I18n.t(`components.notifications_list_item.resolve`)}</Button>
              <Button color='red' onClick={ () => this.props.updateNotification('ignored', this.props.notification.id)}>{I18n.t(`components.notifications_list_item.ignore`)}</Button>
            </div>
          </Card.Content>)
  }

  renderBody() {
    const { notification } = this.props

    if(notification.generateConsultationError) {
      return <GenerateConsultationErrorsListItem generateConsultationError={notification.generateConsultationError}/>
    } else if (notification.removeConsultationError) {
      return <RemoveConsultationErrorsListItem removeConsultationError={notification.removeConsultationError} SubmitButton={this.SubmitButton} />

    } else if (notification.consultationReminder) {
      return <ConsultationReminderListItem consultationReminder={notification.consultationReminder}/>

    }
  }

  render () {
    const { notification } = this.props

    return (
      <Card fluid key={notification.id}>
        <Card.Content>
          <Card.Header onClick={() => this.props.updateUI('isCollapsed', !this.props.ui.isCollapsed)}>
            { this.props.ui.isCollapsed ?  <Icon name="caret right"></Icon> : <Icon name="caret down" ></Icon> }
            {I18n.t(notification.titleKey)}
            {" "}
            { notification.generateConsultationError && notification.generateConsultationError.event ? notification.generateConsultationError.event.summary : "" }
            { notification.consultationReminder && notification.consultationReminder.consultation && notification.consultationReminder.consultation.dossier && notification.consultationReminder.consultation.dossier.patient ? notification.consultationReminder.consultation.dossier.patient.firstName : "" }
            { notification.removeConsultationError && notification.removeConsultationError.event ? notification.removeConsultationError.event.summary : "" }
          </Card.Header>
          { !this.props.ui.isCollapsed ?
              this.renderBody() : null }
        </Card.Content>
      </Card>
      )
  }


}

var NotificationsListItemWithUI = ui({ state: { isCollapsed: true } })(NotificationsListItem);

const mapStateToProps = (state, ownProps) => {

  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotification: (resolutionType, notificationId, matchId = null) => {
      if(matchId) {
        dispatch(updateEntity('notification', { id: notificationId, resolutionType: resolutionType, matchId: matchId }))
      } else {
        dispatch(updateEntity('notification', { id: notificationId, resolutionType: resolutionType }))
      }

      dispatch(upsertEntity('notification', notificationId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsListItemWithUI);
