import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import EntitiesList from 'components/entities/EntitiesList';
import DossiersListItem from 'components/dossiers/DossiersListItem'
import DossierForm from 'components/dossiers/DossierForm'

import {  createEntityWithDefaults } from 'actions/entities';

import { getDossiers } from 'selectors/dossiers'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getDossiers(state),
    entityName: 'dossier',
    item: DossiersListItem,
    form: DossierForm,
    listComponent: Card.Group,
    loading: state.meta.dossier.loading,
    error: state.meta.dossier.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createEntityWithDefaults: () => {
      let contactId = dispatch(createEntityWithDefaults('contact'))
      let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
      let dossierId = dispatch(createEntityWithDefaults('dossier', { patientId: contactId }))
      let dossierSliceId = dispatch(createEntityWithDefaults('dossierSlice', { dossierId: dossierId}))
      return dossierId
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
