import React from 'react'
import { connect } from 'react-redux'
import { Item, Header } from 'semantic-ui-react'
import { fetchEntities } from 'actions/entities';
import pluralize from 'pluralize'
import DossierCommunicationForm from 'components/dossiers/DossierCommunicationForm'
import DossierConsultationsListContainer from 'components/dossiers/DossierConsultationsListContainer'
import DossierItemBatchForm  from 'components/dossier_items/DossierItemBatchForm';
import { getFilteredConsultationsForDossier } from 'selectors/consultations'

class DossierFollowUp extends React.Component {
  componentDidMount() {
      this.props.dispatch(fetchEntities('consultations', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
      this.props.dispatch(fetchEntities('dossierSlices', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.dossierId != prevProps.dossierId) {
      this.props.dispatch(fetchEntities('consultations', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
      this.props.dispatch(fetchEntities('dossierSlices', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
    }
  }

  render() {
    const { dossierId } = this.props;

    if (dossierId) {
      return (
        <DossierItemBatchForm dossierId={dossierId}/>
      )
    } else {
      return (
        <Item>
          Geen dossier
        </Item>
      )
    }
  }
}

export default connect()(DossierFollowUp);
