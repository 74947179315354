import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getOffices = ormSelector(orm, getEntities, (session) => {
  return session.office.all().toModelArray().map(office => {
    const { ref } = office
    return { ...ref}
  })
})

export const getCurrentOfficeId = createSelector([getOffices],
  (offices) => {
    return Object.values(offices).find( office => office.current)?.id
  }
)
