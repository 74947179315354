import React from 'react'
import { Card, Menu, Icon } from 'semantic-ui-react'
import { Popup } from 'semantic-ui-react-with-store'

export default class Event extends React.Component {
  componentDidMount() {
  }

  render () {
    const { event } = this.props
    return (
      <div>
        <Popup as={'div'} trigger={<div>{ event.ignored ?  <Icon name='calendar times'/> : null } {event.summary}</div>} on='click' position='bottom right' style={{padding: '0em'}}>
          <Card fluid>
            <Card.Content>
              <Card.Header>
                {event.summary}
              </Card.Header>
              <Card.Meta>
                <div>
                  <Icon name='clock'/>
                  {event.startTime && I18n.l("time.formats.hour", event.startTime)}
                  -
                  {event.endTime && I18n.l("time.formats.hour", event.endTime)}
                </div>
                <div>
                  <Icon name='calendar'/>
                  {event.calendar && event.calendar.summary}
                </div>
              </Card.Meta>
              { event.consultation ?
                <Card.Description>
                  {  event.consultation.consultationType }
                </Card.Description> : null }
              </Card.Content>
              <Card.Content extra>
                <Menu compact icon borderless secondary size='large'>
                  <Menu.Item icon='pencil'/>
                  <Menu.Item icon='trash'/>
                </Menu>
              </Card.Content>
            </Card>
          </Popup>
        </div>
    )
  }
}

