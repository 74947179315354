const genderSchema = function(state, props) {
  return {
    "ui:emptyValue": "",
    "ui:placeholder": "",
    "ui:widget": "select",
    "ui:group": 'name',
    "ui:options": {
      width: 2,
      searchable: true,
      selectOptions: [
        {key: 'F', icon: 'woman', value: 'F', text: I18n.t('components.dossier_form.gender.woman') },
        {key: 'M', icon: 'man', value: 'M', text: I18n.t('components.dossier_form.gender.man') },
        {key: 'O', icon: 'other gender', value: 'O', text: I18n.t('components.dossier_form.gender.other') },
      ]
    }
  }
}

export default genderSchema;
