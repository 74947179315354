import React from 'react'

import { Form, Header, Menu, Grid, Divider, Message, Icon, Button } from 'semantic-ui-react'

import { createEntityWithRelationship, deleteEntity, updateEntity } from 'actions/entities';

import pluralize from 'pluralize'
import camelize from 'underscore.string/camelize'
import underscored from 'underscore.string/underscored'

import { fetchResources } from 'actions/resources';
import { connect } from 'react-redux'
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'

// TODO switch to dotProp
import get from 'react-redux-form/utils/get'

import { getNestedUiSchema, getUiOptions, getSchema, getEntityNameFromOptions } from 'selectors/forms'

//import dotProp from 'dot-prop'

import {
  retrieveSchema,
  getDefaultRegistry,
} from "react-jsonschema-form/lib/utils";

class SemanticNestedFormField extends ObjectField {
  componentDidMount() {
    this.props.fetchResources();
  }

  render() {
    const {
      uiSchema,
      entityName,
      formData,
      errors,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      onBlur,
      onFocus,
      formState,
      options,
      entityId,
      registry = getDefaultRegistry(),
    } = this.props;

    const { displayTitle, inline, removable } = options;

    let isPending = (formState =="pending")
    let isSubmitted = (formState =="submitted")
    let isSubmitFailed= (formState =="submitFailed")

    const { definitions, fields, formContext } = registry;
    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions);
    const title = schema.title === undefined ? name : schema.title;

    const entitiesName = camelize(pluralize(entityName))
    const fullTranslationScope = `api.forms.${underscored(entityName)}`
    const fullModel = `entities.${entityName}.byId`

    const { titleKey } = (options || {})

    return (
      <div>
        <Grid columns={2} divided padded='vertically'>
          <Grid.Row>
            { displayTitle &&
              <Grid.Column width={2}>
                  <div>
                    <Header as={'a'}>
                      {I18n.t(titleKey || `api.forms.${underscored(entityName)}.title`)}
                    </Header>
                    { !entityId && !readonly ?
                      <Button basic floated='right' icon='plus' onClick={(event) => { event.preventDefault(); this.props.createEntityWithRelationship(this.props.entityName, this.props.entityDesignation || this.props.entityName, this.props.formContext.entityName, this.props.formContext.entityId )}}/> : null }
                    { entityId && removable && !readonly ?
                    <Button basic floated='right' icon='trash' onClick={(event) =>  { event.preventDefault(); this.props.clearEntity(this.props.entityName, this.props.entityId, this.props.formContext.entityName, this.props.formContext.entityId) }}/> : null }
                  </div>
              </Grid.Column>
            }
            <Grid.Column width={14}>
              { !this.props.entityId && this.props.readonly && I18n.t('generic.unknown') }
              { this.props.entityId ?
                <div className={`nested-form ${isSubmitted} ${isSubmitFailed ? 'failed' : ''}`}>
                  <SchemaField
                    name={this.props.entityId}
                    required={this.isRequired(name)}
                    schema={schema}
                    uiSchema={uiSchema}
                    errors={errors}
                    idSchema={idSchema[name]}
                    formData={formData[name]}
                    onChange={this.onPropertyChange(name)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={registry}
                    disabled={disabled}
                    readonly={readonly}
                    entityName={this.props.entityName}
                    model={`${fullModel}`}
                    translationScope={fullTranslationScope}
                    formContext={{entityName: this.props.entityName, entityId: this.props.entityId}}
                  />
                </div> : <div>(leeg)</div>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResources: () => {
      dispatch(fetchResources())
    },
    createEntityWithRelationship: (entityName, entityDesignation, relationshipEntityName, relationshipEntityId) => {
      dispatch(createEntityWithRelationship(entityName, entityDesignation, relationshipEntityName, relationshipEntityId))
    },
    clearEntity: (entityName, entityId, relationshipEntityName, relationshipEntityId) => {
      dispatch(updateEntity(relationshipEntityName,{ id: relationshipEntityId, [`${entityName}Id`]: null }))
      dispatch(deleteEntity(entityName, entityId))
    },

  }
}

const mapStateToProps = (state, ownProps) => {
  var entityName = getEntityNameFromOptions(state,ownProps)

  var entityModel = `${ownProps.model || ''}.${ownProps.name}`
  var entityId = get(state, entityModel)

  const errorModel = `meta.${entityName}.errors.${entityId}`
  const formModel = `meta.${entityName}.forms.${entityId}`

  const errors = get(state, errorModel)
  const form = get(state, formModel)

  return {
    schema: getSchema(entityName)(state,ownProps),
    uiSchema: getNestedUiSchema(state,ownProps),
    errors: errors,
    options: getUiOptions(state,ownProps),
    entityId: entityId,
    entityName: entityName,
    formState: form && form.state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticNestedFormField);
