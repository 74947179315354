import React from 'react'
import { Menu, Button, Item, Segment, Card, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { upsertEntity, updateEntity } from 'actions/entities'

import underscored from 'underscore.string/underscored'

class RemoveConsultationErrorsListItem extends React.Component {
  render () {
    const error = this.props.removeConsultationError
    return (
        <Card.Content>
          <Card.Meta>
            {error.event && error.event.startTime ? I18n.l("time.formats.long", error.event.startTime) : null }
          </Card.Meta>
          <Card.Description>
            {I18n.t(`components.remove_consultation_errors_list_item.error_descriptions.${this.props.errorType}`)}
          </Card.Description>
          <Card.Content>
            <div className='ui buttons right floated'>
              <Button color='green' onClick={ (event) => { this.props.updateRemoveConsultationError('restored', this.props.removeConsultationError.id)}}  >{I18n.t(`components.remove_consultation_errors_list_item.restore`)}</Button> : null
              <Button color='orange' onClick={ (event) => { this.props.updateRemoveConsultationError('ignored', this.props.removeConsultationError.id)}}  >{I18n.t(`components.remove_consultation_errors_list_item.ignore`)}</Button> : null
              <Button color='red' onClick={ (event) => { this.props.updateRemoveConsultationError('removed', this.props.removeConsultationError.id)}}>{I18n.t(`components.remove_consultation_errors_list_item.remove`)}</Button>
            </div>
          </Card.Content>
        </Card.Content>
      )
  }
}


const mapStateToProps = (state, ownProps) => {
  const error = ownProps.removeConsultationError

  return {
    error: error,
    errorType: error.errorType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRemoveConsultationError: (resolutionType, removeConsultationErrorId, matchId = null) => {
      if(matchId) {
        dispatch(updateEntity('removeConsultationError', { id: removeConsultationErrorId, resolutionType: resolutionType, matchId: matchId }))
      } else {
        dispatch(updateEntity('removeConsultationError', { id: removeConsultationErrorId, resolutionType: resolutionType }))
      }

      dispatch(upsertEntity('removeConsultationError', removeConsultationErrorId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveConsultationErrorsListItem);
