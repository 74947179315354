import React from 'react'
import { Input, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'

class SemanticUpDownWidget extends React.Component {
  render() {
    return (<Input value={this.props.value} type="number" min={0} onChange={this.props.handleChange}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    value: dotProp.get(state, fullModel),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`
return {
    handleChange: (event) => {
      dispatch(formActions.change(fullModel, event.target.value))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticUpDownWidget);
