import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'

import { fetchEntities } from 'actions/entities'
import dossierFinancialSchema from 'forms/dossierFinancialSchema'

import ContactForm from 'components/contacts/ContactForm'
import OrganisationForm from 'components/organisations/OrganisationForm'

import dotProp from 'dot-prop'

class DossierFinancialForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <SchemaForm entityName='dossier' {...this.props}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let selectedSliceId = dotProp.get(state, `entities.dossier.byId.${ownProps.entityId}.selecteddossierSlice`)
  let invoiceReceiver = selectedSliceId && dotProp.get(state, `entities.dossierSlice.byId.${selectedSliceId}.invoiceReceiver`)
  return {
    selectedSlice: selectedSliceId,
    invoiceReceiver: invoiceReceiver,
    uiSchema: dossierFinancialSchema(state, ownProps, invoiceReceiver),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('locations'))
      dispatch(fetchEntities('countries'))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('organisations'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null )(DossierFinancialForm);
