import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

import {  createEntityWithDefaults, createEntityWithRelationship } from 'actions/entities';

import NewEntityModal from 'components/entities/NewEntityModal';
import DossierForm from 'components/dossiers/DossierForm';
import ConsultationForm from 'components/consultations/ConsultationForm';
import InvoiceForm from 'components/invoices/InvoiceForm';

class AddNewMenuItem extends React.Component {
  render() {
    let createEntityDefinitions = {
      'dossier': { form: DossierForm, createEntityWithDefaults: this.props.newDossierWithDefaults },
      'consultation': { form: ConsultationForm },
      'invoice': { form: InvoiceForm }
    }

    return (
      <Dropdown item trigger={<Icon name='plus square' size='large'/>}>
        <Dropdown.Menu>
          { Object.keys(createEntityDefinitions).map( (entityName) => {
            let createEntityDefinition = createEntityDefinitions[entityName]

            return (<NewEntityModal key={entityName} entityName={entityName} createEntityWithDefaults={createEntityDefinition.createEntityWithDefaults} form={createEntityDefinition.form} trigger={ (props) => {

              return (<Dropdown.Item as={'a'} text={I18n.t(`components.add_new_menu_item.dropdown_items.${entityName}`)} key={entityName} onClick={props.onClick} />)
            }} />) }) }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    newDossierWithDefaults: () => {
      let contactId = dispatch(createEntityWithDefaults('contact'))
      let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
      let dossierId = dispatch(createEntityWithDefaults('dossier', { patientId: contactId }))
      let dossierSliceId = dispatch(createEntityWithDefaults('dossierSlice', { dossierId: dossierId}))
      return dossierId
    }
  }
}

export default connect(null, mapDispatchToProps)(AddNewMenuItem);
