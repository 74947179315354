import React from 'react'
import { Segment, List, Item, Statistic, Grid, Icon, Label } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

import { actions as formActions } from 'react-redux-form'
import { upsertEntity } from 'actions/entities'

import { connect } from 'react-redux'

class ConsultationsListItem extends React.Component {

  render () {
    const { consultation } = this.props

    return (
      <List.Item key={consultation.id} as={Segment} basic clearing>
        <List.Content as={Segment}>
          <Label corner='left'>
            <Icon name={(consultation.consultationType || {}).icon || 'question'} size='big'/>
          </Label>
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={3} textAlign='center' verticalAlign='middle'>
                <NavLink to={Routes.consultation_path({ id: consultation.id, _options: true})}>
                  { (consultation.event && consultation.event.startTime) ?  <Statistic value={I18n.l("date.formats.human_readable", consultation.event.startTime)} size='tiny'/> : null }
                </NavLink>
              </Grid.Column>
              <Grid.Column width={3} textAlign='center' verticalAlign='middle'>
                <NavLink to={Routes.consultation_path({ id: consultation.id, _options: true})}>
                  { (consultation.event && consultation.event.startTime) ?  <Statistic value={I18n.l("time.formats.hour", consultation.event.startTime)} size='tiny'/> : null }
                </NavLink>
              </Grid.Column>
              <Grid.Column width={8}>
                <NavLink to={Routes.consultation_path({ id: consultation.id, _options: true})}>
                  { ( consultation.dossier && consultation.dossier.patient) ?  <List.Header>{consultation.dossier.patient.firstName} {consultation.dossier.patient.lastName}</List.Header> : <List.Header/>}
                  <List.Description>{(consultation.consultationType || {}).name || I18n.t('components.consultations_list.unknown_consultation_type')}</List.Description>
                </NavLink>
              </Grid.Column>
              <Grid.Column width={2}>
                { this.renderInvoiceCheck() }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </List.Content>
      </List.Item>
    )
  }

  renderInvoiceCheck() {
    const { consultation } = this.props

    if (consultation.invoiceItem && consultation.invoiceItem.invoice && !consultation.invoiceItem.invoice.proposed) {
      return  (
        <NavLink to={Routes.invoice_path({ id: consultation.invoiceItem.invoice.id, _options: true})}>
          <Icon.Group size='large'>
            <Icon name='book'/> <Icon corner name='check circle' color='green'/>

          </Icon.Group>
        </NavLink> )
    } else {
      return  (<Icon.Group size='large' onClick={() => {this.props.toggleNoInvoice(consultation) }}>
        <Icon name='book' link={true}/>
        { consultation.noInvoice && <Icon corner name='ban' color='red'/> }
      </Icon.Group>)
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNoInvoice: (consultation) => {
      dispatch(formActions.change(`entities.consultations.byId.${consultation.id}.attributes.noInvoice`, !consultation.noInvoice))
      dispatch(upsertEntity('consultation', consultation.id))
    },
  };
}

export default connect(null, mapDispatchToProps)(ConsultationsListItem);
