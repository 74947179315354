import ContactForm from 'components/contacts/ContactForm'
import OrganisationForm from 'components/organisations/OrganisationForm'
import dossierSliceSchema from 'forms/dossierSliceSchema'

const dossierFinancialSchema = function(state, props, invoiceReceiver) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "patientId": {
      "ui:widget": "hidden",
    },
    "dossierSlices": {
      "ui:field": "SliceField",
      "ui:options": {
        "entityName": "dossierSlice",
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "dossierSlice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": dossierSliceSchema(state,props, 'financial', invoiceReceiver)
      }
    }
  }
  return uiSchema
}

export default dossierFinancialSchema;
