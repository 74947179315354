import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { fetchEntities, upsertEntity, createEntity } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import OfficesListItem from 'components/admin/offices/OfficesListItem'
import OfficeForm from 'components/offices/OfficeForm'

import { getOffices } from 'selectors/offices'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getOffices(state, ownProps),
    entityName: 'office',
    item: OfficesListItem,
    form: OfficeForm,
    listComponent: Card.Group,
    loading: state.meta.office.loading,
    error: state.meta.office.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('offices'))
    },
    upsertEntity: (entityId) => {
      dispatch(upsertEntity('office', entityId))
    },
    createEntity: () => {
      dispatch(createEntity('office'))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
