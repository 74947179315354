import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

import { getOffices } from 'selectors/offices'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getOffices(state, ownProps),
    entityName: 'office',
    item: (props) => <div>{`${props.office.name}`}</div>,
    loading: state.meta.office.loading,
    error: state.meta.office.error,
    admin: true
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('offices'))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu);
