import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import EmailTemplateBatchForm from 'components/email_templates/EmailTemplateBatchForm';

import { fetchEntities } from 'actions/entities';

import { getEmailTemplates }  from 'selectors/email_templates'



import { connect } from 'react-redux'

class EmailTemplates extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    const { emailTemplates } = this.props
    return (<EmailTemplateBatchForm/>)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    emailTemplates: getEmailTemplates(state, ownProps),
    loading: state.meta.emailTemplate.loading,
    error: state.meta.emailTemplate.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('emailTemplates'))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
