import React from 'react'
import { connect } from 'react-redux'
import { Button, Header, Icon, Input, Form} from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'

class DestroyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {confirmation: ''}
  }

  handleConfirmationChange = (event,props) => {
    this.setState({confirmation: props.value})
  }

  render() {
    return (
      <Modal size='mini' trigger={this.props.trigger} closeIcon>
        <Header content={I18n.t('components.destroy_modal.title')} />
        <Modal.Content>
          {this.props.children}
        </Modal.Content>
        <Modal.Content>
          <Form.Field>
            {I18n.t('components.destroy_modal.confirmation')}
            <Input  onChange={this.handleConfirmationChange}/>
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          {/* <Button color='green' inverted positive> */}
          {/*   <Icon name='cancel' /> {I18n.t('components.destroy_modal.cancel')} */}
          {/* </Button> */}
          <Button color='red' inverted disabled={(this.state.confirmation != 'DELETE')} onClick={this.props.handleDestroy}>
            <Icon name='trash' /> {I18n.t('components.destroy_modal.destroy')}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default DestroyModal;
