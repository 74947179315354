import React from 'react'
import { Header, Segment, Tab, Menu } from 'semantic-ui-react'
import CalendarsListContainer from 'components/configuration/CalendarsListContainer'
import Locations from 'components/locations/Locations'
import EmploymentsListContainer from 'components/employments/EmploymentsListContainer'
import OfficeForm from 'components/offices/OfficeForm'
import EmailTemplates from 'components/email_templates/EmailTemplates'
import ConsultationPrices from 'components/consultation_prices/ConsultationPrices'
import MainApp from 'components/MainApp'
import { connect } from 'react-redux'

import { Route, NavLink } from 'react-router-dom'
import { fetchEntities } from 'actions/entities'
import pathToRegexp from 'path-to-regexp'

import { getOffices } from 'selectors/offices'


class Configuration extends React.Component {
  componentDidMount() {
    this.props.fetchOffices();
  }

  render () {

    const panes = [
      { menuItem: <Menu.Item key={'office'} as={NavLink} to={Routes.configuration_path()}>{I18n.t('components.configuration.office')}</Menu.Item>, render: () => <Tab.Pane basic>
          <Segment>
            <Header as='h1'>
              {I18n.t('components.configuration.office')}
            </Header>
            <Route path="/:officeSlug/*" render={(props) =>
              <OfficeForm entityId={(this.props.offices.filter( (office) => office.slug === props.match.params.officeSlug )[0] || {}).id} fixedStartInvoiceIdentification={(this.props.offices.filter( (office) => office.slug === props.match.params.officeSlug )[0] || {}).fixedStartInvoiceIdentification}/>}/>
          </Segment>
        </Tab.Pane> },

      { menuItem: <Menu.Item key={'locations'} as={NavLink} to={Routes.locations_path()}>{I18n.t('components.configuration.locations')}</Menu.Item> , render: () => <Tab.Pane basic ><Locations/></Tab.Pane> },

      { menuItem: <Menu.Item key={'employments'} as={NavLink} to={Routes.employments_path()}>{I18n.t('components.configuration.employments')}</Menu.Item> , render: () => <Tab.Pane basic ><EmploymentsListContainer/></Tab.Pane> },

      { menuItem: <Menu.Item key={'calendars'} as={NavLink} to={Routes.calendars_path()}>{I18n.t('components.configuration.calendars')}</Menu.Item>, render: () => <Tab.Pane basic><CalendarsListContainer/></Tab.Pane> },

      { menuItem: <Menu.Item key={'consultationPrices'} as={NavLink} to={Routes.consultation_prices_path()}>{I18n.t('components.configuration.consultation_prices')}</Menu.Item> , render: () => <Tab.Pane basic ><ConsultationPrices/></Tab.Pane> },

      { menuItem: <Menu.Item key={'emailTemplates'} as={NavLink} to={Routes.email_templates_path()}>{I18n.t('components.configuration.email_templates')}</Menu.Item> , render: () => <Tab.Pane basic ><EmailTemplates/></Tab.Pane> }
    ]

    return (
      <Route path={"/:officeSlug/*"} render={(props) => {
        var activeIndex = 0
        if (pathToRegexp(Routes.configuration_path()).exec(props.match.url)) {
          activeIndex = 0
        } else if (pathToRegexp(Routes.locations_path()).exec(props.match.url) || pathToRegexp(Routes.location_path({ id: ":id", _options: true })).exec(props.match.url)) {
          activeIndex = 1
        } else if (pathToRegexp(Routes.employments_path()).exec(props.match.url)) {
          activeIndex = 2
        } else if (pathToRegexp(Routes.calendars_path()).exec(props.match.url)) {
          activeIndex = 3
        } else if (pathToRegexp(Routes.consultation_prices_path()).exec(props.match.url)) {
          activeIndex = 4
        } else if (pathToRegexp(Routes.email_templates_path()).exec(props.match.url)) {
          activeIndex = 5
        }

        return <Tab activeIndex={activeIndex}  menu={{secondary: true, pointing: true}} panes={panes}/>}
      }/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    offices: getOffices(state),
    loading: state.meta?.office?.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffices: () => {
      dispatch(fetchEntities('offices'))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
