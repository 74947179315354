
import React, { Component } from "react";
import PropTypes from "prop-types";

import underscored from 'underscore.string/underscored'

import { Grid, Header, Button, Icon, Menu, Segment, Divider, Label, Sticky } from 'semantic-ui-react';

import { replaceEntities, receiveEntities } from 'actions/entities';

import { connect } from 'react-redux'

import ui from 'redux-ui'

import { actions } from 'react-redux-form'

import dotProp from 'dot-prop'

import pluralize from 'pluralize'

import { Value, Selection } from 'slate'

import ConsultationsListItem from 'components/consultations/ConsultationsListItem'

import DossierItemsSerializer from 'slate-dossier-items-serializer'

import { getDossierItemsJson, getDossierItemsForDossier } from 'selectors/dossier_items'

import _ from 'lodash'

import DossierEditor from 'components/forms/editor/DossierEditor'

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    onChange: (change, props) => {
      const { value, operations } = change
      // console.log(operations.toJSON())
      props.updateUI('selection', value.selection.toJSON())
      // No history for now
      // props.updateUI('editorData', value.data.toJSON())

      let blocks = ["note", "attachment", "text", "paragraph", "block_quote","bulleted_list", "header_one", "header_two", "header_three", "header_four", "header_five", "header_six", "list_item", "dossier", "consultation", "slice"]
      let serializer = new DossierItemsSerializer({ filters: blocks })
      let items = serializer.serialize(value)
      console.log("serialized Items")
      console.log(items)
      let newItems = {}

      Object.keys(items).forEach( (itemId) => {
        let item = items[itemId]
        if(item.dossierItemType != "dossier" && item.dossierItemType != "consultation" && item.dossierItemType != "slice") {
          let newPath = item.path.split(".").slice(1).join(".")
          newItems[itemId] = {...item, ...{ id: itemId, path: newPath } }
        }
      })
      dispatch(replaceEntities('dossierItem', props.previousItems.map(item => item.id), newItems))
    },
  }
}

const mapStateToProps = (state, ownProps) => {
  const dossierId = ownProps.formContext.entityId

  console.log("ok we should get them now")

  return {
    previousItems: dossierId && getDossierItemsForDossier(state, { dossierId: dossierId}),
    json: dossierId && getDossierItemsJson(state, { dossierId: dossierId })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ui({state: { selection: Selection.create().toJSON() }})(DossierEditor));
