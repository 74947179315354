import React from 'react'
import BigCalendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import { Menu, Icon, Segment } from 'semantic-ui-react'
import { filterEntities } from 'actions/entities'
import Event from 'components/agenda/Event'
import Toolbar from 'components/agenda/Toolbar'
import NewEventsList from 'components/agenda/NewEventsList'
import { DragDropContext} from 'react-dnd'
import ui from 'redux-ui'

import html5Backend from 'react-dnd-html5-backend'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
moment.locale(I18n.currentLocale());
moment.locale('nl');
BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

const components = {
  event: Event,
  toolbar: Toolbar
}

const DragAndDropCalendar = withDragAndDrop(BigCalendar, {backend: false}) // Provide an own shared backend


class EventsCalendar extends React.Component {
  componentDidUpdate(prevProps) {
    if ((prevProps.ui.date != this.props.ui.date) || (prevProps.ui.view != this.props.ui.view)) {
      this.filter(this.props.ui.date, this.props.ui.view);
    }
  }

  handleNavigate(date, view , action) {
    this.props.updateUI("date", date.toISOString())
    this.props.updateUI("view", view)
    this.filter(date, view)
  }

  filter(date, view) {
    let startTime = moment(date).startOf(view).toISOString()
    let endTime = moment(date).endOf(view).toISOString()
    this.props.fetchEvents(startTime, endTime);
    this.props.fetchConsultations(startTime, endTime)
  }

  handleView(view) {
    this.props.updateUI('view', view)
    this.filter(this.props.ui.date, view)
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.displayColor;
    var style = {
      backgroundColor: 'red',//  backgroundColor,
      borderRadius: '0px',
      opacity: event.markedForRemoval ? 0.1 : 0.8,
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  }

  render () {
    return (
      <div>
        { this.props.ui.isNewEventsListVisible ?
        <NewEventsList/> : null
        }
        <Segment clearing basic loading={this.props.loading}>
          <DragAndDropCalendar
            events={this.props.events}
            messages={I18n.t('components.big_calendar')}
            culture={I18n.currentLocale()}
            startAccessor='startTime'
            endAccessor='endTime'
            titleAccessor='summary'
            onNavigate={(date,view,action) => this.handleNavigate(date, view,action) }
            onEventDrop={this.props.acceptEvent}
            onView={(view) => this.handleView(view) }
            date={new Date(this.props.ui.date) || new Date()}
            view={this.props.ui.view}
            defaultView={'week'}
            min={new Date(2018,1,1,8,0,0)}
            max={new Date(2018,1,1,23,0,0)}
            eventPropGetter={this.eventStyleGetter}
            popup
            selectable
            views={['day','week','month']}
            components={components}
          />
        </Segment>
      </div>
    )
  }
}


export default ui({ key: 'eventsCalendar',  state: { isNewEventsListVisible: false, date: (new Date()).toISOString(), view: 'week'  } })(DragDropContext(html5Backend)(EventsCalendar));
