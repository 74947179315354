import React from 'react'
import { connect } from 'react-redux'

import { Item, Header } from 'semantic-ui-react'
import { fetchEntities } from 'actions/entities';
import DossierForm from 'components/dossiers/DossierForm'

class DossierBasicInfo extends React.Component {
  componentDidMount() {
      this.props.dispatch(fetchEntities('dossierSlices', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.dossier && prevProps.dossier && (this.props.dossier.id != prevProps.dossier.id)) {
      this.props.dispatch(fetchEntities('dossierSlices', { name: 'dossier', filter: { dossierId: this.props.dossierId }, page: 1 }))
    }
  }

  render() {
    const dossier = this.props.dossier;

    if (dossier) {
      return (
        <div>
          <DossierForm entityId={dossier.id}/>
        </div>
      )
    } else {
      return (
        <Item>
          Geen dossier
        </Item>
      )
    }
  }
}

export default connect()(DossierBasicInfo);
