import React from 'react'
import PropTypes from "prop-types";

import { Form, Label} from 'semantic-ui-react'
import underscored from 'underscore.string/underscored'
import { connect } from 'react-redux'
import { actions as formActions } from 'react-redux-form'

export default class SemanticFieldTemplate extends React.Component {
  render () {
    const {id, classNames, label, help, required, description, errors, children, entityName, formContext, displayLabel} = this.props;
    const { "ui:options": options , "ui:placeholder": placeholder}   = this.props.uiSchema
    const { width } = (options || {})
    const localEntityName = entityName || formContext?.entityName
    return (
      <Form.Field className={classNames} width={width || 16}>
        { displayLabel ? <label htmlFor={id}>{I18n.t(`api.forms.${underscored(localEntityName)}.${underscored(label)}`)}{required ? "*" : null}</label> : null }
        {description}
        {children}
        { errors ? <Label basic color='red' pointing>{errors}</Label> : null }
        {help}
      </Form.Field>
    );
  }
}
